import { Button } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import axiosWithAuth from "../../../utils/axiosWithAuth";
import RegisterAttendee from "./RegisterAttendee";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import ShareBar from "./ShareBar";

export default function Sidebar({ eventData, user }) {
  const [isAttending, setIsAttending] = useState(false);
  const metaData = eventData.meta_data;

  useEffect(() => {
    if (user.id) {
      const APIURL = `/api/event-ticket/attending`;

      axiosWithAuth()
        .get(APIURL)
        .then((res) => {
          setIsAttending(res.data);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  }, [user]);

  //* Function to go to live event
  const joinEvent = () => {
    console.log("eventData", eventData);
    const APIURL = `/api/event/online/token`;

    axiosWithAuth()
      .post(APIURL, { eventData })
      .then((res) => {
        console.log(res);
        window.location.href = `/onlineEvent/${eventData.id}?t=${res.data.token}`;
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  return (
    <>
      <div className="widget-box button-widget">
        {isAttending ? (
          <Button type="primary" onClick={joinEvent}>
            Join Event
          </Button>
        ) : (
          <RegisterAttendee eventData={eventData} />
        )}
      </div>

      <div className="widget-box">
        <h3>Event Time</h3>

        <div className="meta-data">
          <div className="label">Event Date:</div>
          <div className="data">
            {moment(metaData.event_date).format("MMM. Do, YYYY")}
          </div>
        </div>

        <div className="meta-data">
          <div className="label">Start:</div>
          <div className="data">
            {moment(metaData.event_starttime, "HH:mm").format("hh:mm A")}
          </div>
        </div>

        <div className="meta-data">
          <div className="label">End:</div>
          <div className="data">
            {moment(metaData.event_endtime, "HH:mm").format("hh:mm A")}
          </div>
        </div>

        <AddToCalendarButton
          name={eventData.name}
          options={["Apple", "Google", "Microsoft365", "Outlook.com", "iCal"]}
          location={
            metaData.event_mode === "online" ? "Online" : metaData.event_venue
          }
          startDate={moment(metaData.event_date).format("YYYY-MM-DD")}
          endDate={moment(metaData.event_date).format("YYYY-MM-DD")}
          startTime={metaData.event_starttime}
          endTime={metaData.event_endtime}
          timeZone="America/New_York"
          hideCheckmark={true}
          trigger={"click"}
        ></AddToCalendarButton>
      </div>

      <div className="widget-box">
        <h3>Event Details</h3>

        <div className="meta-data">
          <div className="label">Capacity:</div>
          <div className="data">{metaData.event_capacity} Attendees</div>
        </div>

        <div className="meta-data">
          <div className="label">Category:</div>
          <div className="data">{metaData.event_category}</div>
        </div>

        <div className="meta-data">
          <div className="label">Location:</div>
          <div className="data">
            {metaData.event_mode === "online" ? "Online" : metaData.event_venue}
          </div>
        </div>
      </div>

      <div className="widget-box">
        <ShareBar eventData={eventData} />
      </div>

      <div className="widget-box button-widget">
        {isAttending ? (
          <Button type="primary" onClick={joinEvent}>
            Join Event
          </Button>
        ) : (
          <RegisterAttendee eventData={eventData} />
        )}
      </div>
    </>
  );
}
