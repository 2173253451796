//** Import Modules */
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userGeneralData } from "./features/reducers/user";
import { getCookie } from "./common/manageCookies";

//** Import Assets */
import "./assets/css/App.css";

//** Import Components */
import MainFooter from "./components/footer/Footer";
import NotificationManager from "./components/common/NotificationManager";
import ScrollToTop from "./components/common/ScrollToTop";
import MainHeader from "./components/header/Header";
import AboutUs from "./routes/AboutUs/AboutUs";
import Directory from "./routes/Directory/Directory";
import Event from "./routes/Events/Event";
import PublicGroup from "./routes/Groups/Group";
import Homepage from "./routes/Homepage/Homepage";
import NotFound from "./routes/NotFound/NotFound";
import Terms from "./routes/PolicyPages/Terms";
import Profile from "./routes/Profile/Profile";
import City from "./routes/Cities/Cities";
import CityDeails from "./routes/Cities/CityDetails";
import Zoom from "./routes/Events/Zoom";
import Group from "./routes/GroupProfile/Group";
import Privacy from "./routes/PolicyPages/Privacy";
import CustomerCheckout from "./routes/CustomerCheckout/CustomerCheckout";
import PrivateRoute from "./common/PrivateRoute";
import OrderConfirmationPage from "./routes/CustomerCheckout/OrderConfirmationPage";
import FreeTrialCPC from "./routes/TempRoutes/FreeTrialCPC";
import MobileFooterBar from "./components/footer/MobileFooterBar";
import SingleEvent from "./routes/Events/SingleEvent/SingleEvent";

function App() {
  window.onbeforeunload = () => {
    window.localStorage.removeItem("chatbox");
  };

  //* Get the data of the logged in user, if we have a token available
  const dispatch = useDispatch();
  const getToken = getCookie("ubt");

  useEffect(() => {
    if (getToken) {
      dispatch(userGeneralData());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      <div className="App">
        <ScrollToTop />
        <div id="page-wrapper">
          <MainHeader />

          <main id="main-page-content">
            <Routes>
              {/* Homepage Route */}
              <Route path="/" element={<Homepage />} exact />

              {/* About Page Route */}
              <Route element={<AboutUs />} path="/about" />

              {/* Directory Routes */}
              <Route element={<Directory />} path="/directory" />

              {/* Cities Route */}
              <Route element={<City />} path="/bizzll-city" exact />
              <Route element={<CityDeails />} path="/bizzll-city/:city" exact />

              {/* Events Routes */}
              <Route element={<Event />} path="/events" />
              <Route element={<SingleEvent />} path="/events/:id" />
              <Route element={<Zoom />} path="/onlineEvent/:id" />

              {/* Groups Routes */}
              <Route element={<PublicGroup />} path="/groups" />
              <Route element={<Group />} path="/group/:id" />

              {/* Business Page Route */}
              <Route element={<Profile />} path="/business/:username/*" />

              {/* Policy Routes */}
              <Route element={<Terms />} path="/terms-conditions" />
              <Route element={<Privacy />} path="/privacy-policy" />

              {/* Shopping Routes */}
              <Route
                element={
                  <PrivateRoute>
                    <CustomerCheckout />
                  </PrivateRoute>
                }
                path="/checkout/*"
              />
              <Route
                element={<OrderConfirmationPage />}
                path="/order-confirmation/:id"
              />

              {/* Temp Coupon Redirect URL */}
              <Route element={<FreeTrialCPC />} path="/cp/:code" />

              {/* Error Routes */}
              <Route element={<NotFound />} />
            </Routes>
          </main>

          <MainFooter />
        </div>
      </div>

      <MobileFooterBar />

      <NotificationManager />
    </>
  );
}

export default App;
