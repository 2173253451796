import React from "react";
import { Modal, Button } from "antd";

const DeleteModal = (props) => {
  const { showModal, setShowModal, deleteAdvertisement, deleteReview } = props;
  return (
    <div>
      <Modal
        title={deleteReview ? "Delete Review" : "Delete Post"}
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={[
          <Button
            key="back"
            onClick={() => setShowModal(false)}
            className="cancel-ad-btn"
            type="primary"
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={async () => {
              setShowModal(false);
              await deleteAdvertisement();
            }}
            className="delete-ad-btn"
          >
            Delete
          </Button>,
        ]}
      >
        {deleteReview ? "Are you sure you want to delete this Review?" : "Are you sure you want to delete this Advertisement?"}
      </Modal>
    </div>
  );
};

export default DeleteModal;
