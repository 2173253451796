import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PageTitle from "../../../components/header/PageTitle";
import { getEvent } from "../../../features/reducers/event";
import AboutOrganizer from "./AboutOrganizer";
import Sidebar from "./Sidebar";

export default function SingleEvent() {
  //* Get the event info from DB via ID
  const { id } = useParams();

  const dispatch = useDispatch();
  const eventData = useSelector((state) => {
    return state.events.event;
  });
  const user = useSelector((state) => state.user.user);
  console.log("eventData: ", eventData);

  useEffect(() => {
    dispatch(getEvent(id));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div id='bizzll-page'>
      <Helmet>
        <title>{eventData.name} - BIZZLL Events</title>
        <meta name='description' content='This is the Events of Bizzll' />
      </Helmet>

      <PageTitle pageTitle={eventData.name} />

      <div id='bizzll-event' className='page-with-sidebar'>
        <div className='inner-container'>
          <div className='page-content'>
            <div className='event-promo'>
              <img src={eventData.meta_data.event_image} alt='' />
            </div>
            <div className='description'>{eventData.meta_data.event_description}</div>
          </div>

          <aside className='page-sidebar' id='event-sidebar'>
            <Sidebar eventData={eventData} user={user} />
          </aside>

          <AboutOrganizer eventData={eventData} />
        </div>
      </div>
    </div>
  );
}
