import React from "react";
import ChatBox from "./ChatBox";

export default function ChatBoxContainer(props) {
  const { chatboxes, closeChatbox } = props;

  return (
    <div id="message-chat-boxes">
      {chatboxes && <ChatBox chatbox={chatboxes} closeChatbox={closeChatbox} />}
    </div>
  );
}
