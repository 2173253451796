//** Import Modules */
import { Button } from "antd";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//** Import Assets */
import noImage from "../../assets/images/Bizzll-DirectorySquare.png";

//** Import Components */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axiosWithAuth from "../../utils/axiosWithAuth";

export default function GroupListingItem(props) {
  const { group, setIsGroupAction } = props;
  const user = useSelector((state) => state.user.user);
  const [loadingBtn, setLoadingBtn] = useState(false);

  const [buttonName, setButtonName] = useState("");
  const [isOwner, setIsOwner] = useState(false);

  useEffect(() => {
    if (user && user.id) {
      setButtonName(group && group.isMember ? "Leave Group" : "Join Group");
      if (group.owner_id === user.id) {
        setIsOwner(true);
      }
    }
  }, [group, user]);

  const listingData = {
    id: group.id,
    coverImg: group.meta_data.group_cover || noImage,
    name: group.name,
    description: group.description,
    isMember: group.isMember,
    isBlocked: group.isBlocked,
    addBorder: group.meta_data.business_front === "" ? "add-border" : "",
  };

  const groupAction = async (id, action) => {
    setLoadingBtn(true);
    if (action == "joinGroup") {
      const APIURL = `/api/group/joinGroup/${id}`;
      const res = await axiosWithAuth().post(APIURL);
      if (res.status == 201) {
        setButtonName("Leave Group");
        setIsGroupAction(true);
      }
    } else {
      const APIURL = `/api/group/leaveGroup/${id}`;
      const res = await axiosWithAuth().post(APIURL);
      if (res.status == 201) {
        setButtonName("Join Group");
        setIsGroupAction(true);
      }
    }
    setLoadingBtn(false);
  };

  // Build URL
  const groupURL = `/group/${group.id}`;

  return (
    <a href={groupURL} className='listing-item'>
      <div className={`cover-img ${listingData.addBorder}`}>
        <div
          className='image'
          style={{ backgroundImage: `url(${listingData.coverImg})` }}
        ></div>
      </div>

      <div className='group-info'>
        <div className='group-name'>
          <h3>{listingData.name}</h3>
        </div>
        <div className='group-description'>
          {listingData.description &&
            listingData.description.substring(0, 90) + "..."}
        </div>
      </div>

      <Button type='primary' className='visit-btn'>
        <FontAwesomeIcon icon={faArrowRight} /> Visit Group
      </Button>
    </a>
  );
}
