import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar } from "antd";
import moment from "moment";
import { useState } from "react";
import DeleteCommentModal from "./DeleteComment";

const Comment = (props) => {
  const {
    comment,
    user,
    isToday,
    deleteComment,
    entity,
    advertisement,
    userBlocked,
    group,
  } = props;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  return (
    <div className="comment-header">
      <div>
        <Avatar size={35}>{`${comment.userInfo.user_fname
          .substring(0, 1)
          .toUpperCase()} ${comment.userInfo.user_lname
          .substring(0, 1)
          .toUpperCase()}`}</Avatar>
      </div>
      <div className="post-header-name comment">
        <div className="post-owner-name">
          {`${comment.userInfo.user_fname
            .substring(0, 1)
            .toUpperCase()}${comment.userInfo.user_fname.substring(
            1,
            comment.userInfo.user_fname.length
          )} ${comment.userInfo.user_lname
            .substring(0, 1)
            .toUpperCase()}${comment.userInfo.user_lname.substring(
            1,
            comment.userInfo.user_lname.length
          )}`}
          <span>
            {isToday(comment.created)
              ? moment.utc(comment.created).local().format("LT")
              : moment.utc(comment.created).local().format("L")}
          </span>
        </div>
        <div className="post-time">{comment.text}</div>
      </div>
      {(group.owner_id === user.id || advertisement.user_id === user.id || comment.sender_id === user.id) &&
        !userBlocked && (
          <div className="comment-delete">
            <FontAwesomeIcon
              icon="trash-alt"
              onClick={() => setShowDeleteModal(true)}
            />
          </div>
        )}
      <DeleteCommentModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        deleteComment={deleteComment}
      />
    </div>
  );
};

export default Comment;
