import gsap from "gsap";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Button } from "antd";
import { useCreateOrder } from "../../hooks/useCreateOrder";

export default function FreeProductOrder(props) {
  const { user, currentStep, cartItems, subTotal, amountTotal } = props;

  //* Set States
  const [collapse, setCollapse] = useState(false);
  const [divClass, setDivClass] = useState("");

  //* Get the hook to handle creating an order
  const [createOrder, isSubmitting] = useCreateOrder(
    user,
    cartItems,
    subTotal,
    amountTotal
  );

  //* Set the divs class
  useEffect(() => {
    let classNames;

    if (currentStep > 3) {
      classNames = "";
      setCollapse(true);

      gsap.to("#payment-information .step-content", {
        height: 0,
        padding: 0,
        duration: 0.3,
      });
    } else if (currentStep < 3) {
      classNames = "";
      setCollapse(true);

      gsap.to("#payment-information .step-content", {
        height: 0,
        padding: 0,
        duration: 0.3,
      });
    } else {
      classNames = "is-active incomplete";
      setCollapse(false);

      gsap.to("#payment-information .step-content", {
        height: "auto",
        padding: 10,
        duration: 0.3,
      });
    }

    setDivClass(classNames);
  }, [currentStep]);

  return (
    <div
      id='payment-information'
      className={divClass ? `step-wrapper ${divClass}` : "step-wrapper"}
    >
      <div className='step-heading'>
        <div className='icon'>
          {collapse && currentStep > 2 ? (
            <FontAwesomeIcon icon={faCheck} />
          ) : (
            "3"
          )}
        </div>

        <div className='title'>Payment Method</div>
      </div>

      <div className='step-content'>
        <h4>
          By making an order in BIZZLL, you are agreeing to our{" "}
          <a href='/terms-conditions' target='_blank'>
            Terms & Conditions
          </a>
        </h4>

        <Button
          type='primary'
          disabled={isSubmitting}
          loading={isSubmitting}
          onClick={createOrder}
        >
          Process Order
        </Button>
      </div>
    </div>
  );
}
