//** Import Modules */
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import createSagaMiddleware from "@redux-saga/core";

//** Import Reducers */
import userGeneralReducer from "./reducers/user";

//** Setup Redux Saga Middleware */
import watcherSaga from "./sagas/rootSaga";
import messagesReducer from "./reducers/messages";
import eventsReducer from "./reducers/event";

//* Setup middleware
const sagaMiddleware = createSagaMiddleware();

//** Combine reducers */
const reducer = combineReducers({
  user: userGeneralReducer,
  userMessages: messagesReducer,
  events: eventsReducer,
});

//** Configure store */
export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(watcherSaga);
