//** Define Actions */
export const GET_USER_MESSAGES = "GET_USER_MESSAGES";
export const SET_USER_MESSAGES = "SET_USER_MESSAGES";

//** Create action functions */
export const getUserMessages = (user_id) => ({
  type: GET_USER_MESSAGES,
  user_id,
});

export const setUserMessages = (messageList) => ({
  type: SET_USER_MESSAGES,
  messageList,
});

//** Create initial state */
const initialState = {
  messageList: [],
};

//** Create the handler function */
const messagesReducer = (
  state = initialState,
  { type, messageList }
) => {
  switch (type) {
    case SET_USER_MESSAGES:
      return {
        ...state,
        messageList,
      };
    default:
      return state;
  }
};

export default messagesReducer;