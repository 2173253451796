//** Import Assets */
import noImage from "../../assets/images/Bizzll-DirectorySquare.png";

export default function ListingItem(props) {
  const { city } = props;

  const listingData = {
    name: city.name,
    thumbnail: city.city_thumbnail ? city.city_thumbnail : noImage,
  };

  let cityURL = `/bizzll-city/${listingData.name}`;

  return (
    <>
      <a href={cityURL} className='listing-item'>
        <div className='cover-img'>
          <div
            className='image'
            style={{
              backgroundSize: "cover",
              backgroundImage: `url(${listingData.thumbnail})`,
            }}
          ></div>
        </div>

        <div className='city-name'>
          <h3>{listingData.name}</h3>
        </div>
      </a>
    </>
  );
}
