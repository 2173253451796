//** Temp Assets */
import { Button, Input } from "antd";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Advertisements from "../../components/ads/Advertisements";

export default function EventSidebar(props) {
  const search = useLocation().search;
  const [groupName, setGroupName] = useState(
    new URLSearchParams(search).get("keyword")
  );
  const navigate = useNavigate();
  const handleKeydown = (event) => {
    if (event.key === "Enter") {
      navigate(`/groups?keyword=${groupName}`);
    }
  };

  return (
    <>
      <div className='ad-space'>
        <Advertisements addLocation='eventsSidebar' type='square' />
      </div>

      <div className='widget-box search-form'>
        <h3>Search by Name</h3>

        <Input
          onChange={(e) => setGroupName(e.target.value)}
          type='text'
          placeholder='Group Name'
          value={groupName}
          onKeyDown={handleKeydown}
        />

        <Button
          type='primary'
          onClick={() => {
            navigate(groupName ? `/groups?keyword=${groupName}` : `/groups`);
          }}
        >
          Search
        </Button>
      </div>
    </>
  );
}
