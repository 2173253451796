import { useState } from "react";

export const useOpenChatbox = (sender, receiver, is_business) => {
  const [chatboxes, setChatboxes] = useState();

  //* Open a chatbox
  const openChatbox = () => {
    const newChatbox = {
      sender,
      receiver,
      is_business,
    };

    setChatboxes(newChatbox);
  };

  //* Close Chatbox
  const closeChatbox = () => {
    setChatboxes();
    window.localStorage.removeItem("chatbox");
  };

  // Return all necessary functions/states
  return [chatboxes, openChatbox, closeChatbox];
};
