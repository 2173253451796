import { takeLatest, all } from "redux-saga/effects";
import { GET_EVENTS } from "../reducers/event";
import { GET_USER_MESSAGES } from "../reducers/messages";
import { USER_GENERAL_DATA } from "../reducers/user";
import { handleGetEvent } from "./handlers/event";
import { handleGetUserMessage } from "./handlers/messages";

//** Import Handler functions */
import { handleUserGeneralData } from "./handlers/user";

export default function* watcherSaga() {
  yield all([
    yield takeLatest(USER_GENERAL_DATA, handleUserGeneralData),
    yield takeLatest(GET_USER_MESSAGES, handleGetUserMessage),
    yield takeLatest(GET_EVENTS, handleGetEvent),
  ]);
}
