// Import Modules
import { DASHBOARD_URL } from "./constants";
import { getCookie } from "./manageCookies";

export default function PrivateRoute({ children }) {
  const getToken = getCookie("ubt");

  const redirectURL = window.location;

  if (!getToken || getToken === undefined) {
    window.location.replace(`${DASHBOARD_URL}/?redirect=${redirectURL}`);

    return null;
  }

  return children;
}
