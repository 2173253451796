import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import ReactPlayer from "react-player";

const UploadMedia = (props) => {
  const { setMedia, value: media } = props;
  const [file, setFile] = useState();
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    setMedia(acceptedFiles[0]);
    setFile(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <div {...getRootProps()} className="uplaod-image-container">
      <div>
        <input {...getInputProps()} />
        {!media && !file ? (
          <div>
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <p>Drag & Drop Files or click to browse media on your device. </p>
            )}
          </div>
        ) : (file && file.type == "video/mp4") ||
          (media &&
            media.substring &&
            media.substring(media.length - 3, media.length) == "mp4") ? (
          <>
            <ReactPlayer
              url={file ? file.preview : media}
              widht="200px"
              height="100px"
              playing
              className="video-player"
            />
            <FontAwesomeIcon
              icon="times"
              className="uplaod-close-icon"
              onClick={(e) => {
                e.stopPropagation();
                setFile();
                setMedia("");
              }}
            />
          </>
        ) : (
          <>
            <img
              src={file ? file.preview : media}
              alt=""
              className="post-upload-image"
            />
            <FontAwesomeIcon
              icon="times"
              className="uplaod-close-icon"
              onClick={(e) => {
                e.stopPropagation();
                setFile();
                setMedia("");
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default UploadMedia;
