import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Dropdown, Menu, Rate } from "antd";
import moment from "moment";
import { useState } from "react";
import Comment from "./Comment";
import CommentAdForm from "./CommentAdForm";
import CommentModal from "./CommentModal";
import CreateAdModal from "./CreateAdModal";
import DeleteModal from "./DeleteModal";
import LikeModal from "./LikeModal";
import ShareModal from "./ShareModal";

const SingleAdvertisement = (props) => {
  const {
    business,
    advertisement,
    user,
    deleteAdvertisement,
    updateAdvertisement,
    isSubmitting,
    likeAdvertisement,
    unlikeAdvertisement,
    commentAdvertisement,
    liked,
    commentData,
    getAllReviewsOfBusiness,
    shareAdvertisement,
    deleteComment,
    entity,
    reviewSection,
    userData,
  } = props;
  const [showLikeModal, setShowLikeModal] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleAdAction = async (e) => {
    if (e.key === "delete-ad") {
      setShowDeleteModal(true);
    } else if (e.key === "update-ad") {
      setShowUpdateModal(true);
    }
  };

  const handleAdShare = async (e) => {
    if (e.key === "facebook") {
      // setShowDeleteModal(true);
    } else if (e.key === "twitter") {
      // setShowUpdateModal(true);
    }
  };

  const menu = (
    <Menu onClick={handleAdAction}>
      <Menu.Item key='update-ad'>Edit</Menu.Item>
      <Menu.Item key='delete-ad' className='menu-text'>
        Delete
      </Menu.Item>
    </Menu>
  );

  const shareMenu = () => (
    <div>
      {reviewSection ? null : (
        <Menu onClick={handleAdShare}>
          <Menu.Item key='facebook'></Menu.Item>
          <Menu.Item key='twitter'></Menu.Item>
        </Menu>
      )}
    </div>
  );

  const isToday = (someDate) => {
    const today = new Date();
    // const someDateArray = someDate.split(" ")[0].split("-");
    // return (
    //   someDateArray[2] == today.getDate() &&
    //   someDateArray[1] == today.getMonth() + 1 &&
    //   someDateArray[0] == today.getFullYear()
    // );
  };

  return (
    <div className='single-post-container' id={advertisement.id} tabIndex='-1'>
      <div className='post-header'>
        <div>
          {reviewSection ? (
            <Avatar 
              style={{ backgroundColor: '#8620ce' }} 
              size={35}>{`${userData.user_fname
              .substring(0, 1)
              .toUpperCase()}`}</Avatar>
          ) : (
              <Avatar style={{ backgroundColor: '#8620ce' }} size={35}>{`${business.name
              .substring(0, 1)
              .toUpperCase()}`}</Avatar>
          )}
        </div>
        <div className='post-header-name'>
          <div className='post-owner-name'>
            {reviewSection
              ? `${userData.user_fname
                  .substring(0, 1)
                  .toUpperCase()}${userData.user_fname.substring(
                  1,
                  userData.user_fname.length
                )}`
              : `${business.name
                  .substring(0, 1)
                  .toUpperCase()}${business.name.substring(
                  1,
                  business.name.length
                )}`}
          </div>
          <div className='post-time'>
            {isToday(advertisement.created)
              ? moment.utc(advertisement.created).local().format("LT")
              : moment.utc(advertisement.created).local().format("L")}
            {advertisement.modified !== advertisement.created && (
              <div className='post-edited'>Edited</div>
            )}
          </div>
        </div>
        {/* user.id == comment.userInfo.id */}
        {(entity === "business"
          ? business.owner_id === user.id
          : advertisement.user_id === user.id) && (
          <div className='post-action'>
            <Dropdown
              overlay={menu}
              trigger={["click"]}
              placement='bottomLeft'
              arrow
            >
              <FontAwesomeIcon icon='ellipsis-h' />
            </Dropdown>
          </div>
        )}
      </div>
      <div className='post-content'>
        <div className='post-text'>{advertisement.text}</div>
        {reviewSection && (
          <div>
            <Rate
              defaultValue={advertisement.rating}
              disabled={true}
              // style={{
              //   color: "#f64f59",
              // }}
            />
          </div>
        )}
        <div className='post-media'>
          {advertisement.attachments && (
            <>
              {advertisement.attachments.substring(
                advertisement.attachments.length - 3,
                advertisement.attachments.length
              ) === "mp4" ? (
                <video controls className='post-attachments'>
                  <source src={advertisement.attachments} type='video/mp4' />
                </video>
              ) : (
                <img
                  alt="Advertisement"
                  src={advertisement.attachments}
                  className='post-attachments'
                />
              )}
            </>
          )}
        </div>
        <hr />
        {reviewSection ? null : (
          <div className='post-like-comment'>
            <div className={liked ? "post-like-active" : "post-like"}>
              <FontAwesomeIcon
                icon='thumbs-up'
                onClick={async () => {
                  if (liked) {
                    await unlikeAdvertisement(advertisement.id);
                    return;
                  }
                  await likeAdvertisement(advertisement.id);
                }}
              />
              <h3
                onClick={() => setShowLikeModal(true)}
              >{`${advertisement.likeData.length} likes`}</h3>
            </div>
            <div className='post-comment'>
              <h3
                onClick={() => setShowCommentModal(true)}
              >{`${commentData.length} comments`}</h3>
              <FontAwesomeIcon
                icon='comments'
                onClick={() => document.getElementById("comment-input").focus()}
              />
            </div>
            <div className='post-comment'>
              <h3
                onClick={() => setShowShareModal(true)}
              >{`${advertisement.shareData.length} shares`}</h3>

              <Dropdown
                overlay={shareMenu}
                trigger={["click"]}
                placement='bottomLeft'
                arrow
              >
                <FontAwesomeIcon
                  icon='share'
                  // onClick={() => document.getElementById("comment-input").focus()}
                />
              </Dropdown>
            </div>
          </div>
        )}
      </div>
      <CommentAdForm
        commentAdvertisement={(data) =>
          commentAdvertisement(advertisement.id, data)
        }
        getAllReviewsOfBusiness={getAllReviewsOfBusiness}
      />
      <div className='recent-comments'>
        <hr />
        {commentData.length && commentData.length > 2
          ? commentData
              .filter((elem, index) => index < 10)
              .map((comment) => (
                <Comment
                  comment={comment}
                  user={user}
                  isToday={isToday}
                  deleteComment={() =>
                    deleteComment(
                      comment.id,
                      !reviewSection
                        ? comment.activity_wall_id
                        : comment.review_id,
                      comment.sender_id
                    )
                  }
                  business={business}
                  entity={entity}
                  advertisement={advertisement}
                />
              ))
          : commentData.length
          ? commentData.map((comment) => (
              <Comment
                comment={comment}
                user={user}
                isToday={isToday}
                deleteComment={() =>
                  deleteComment(
                    comment.id,
                    !reviewSection
                      ? comment.activity_wall_id
                      : comment.review_id,
                    comment.sender_id
                  )
                }
                business={business}
                entity={entity}
                advertisement={advertisement}
              />
            ))
          : reviewSection
          ? "There is no comment on this review"
          : "There is no comment on this advertisement"}
      </div>
      {!reviewSection && (
        <LikeModal
          showModal={showLikeModal}
          setShowModal={setShowLikeModal}
          likeData={advertisement.likeData}
        />
      )}

      <CommentModal
        showModal={showCommentModal}
        setShowModal={setShowCommentModal}
        commentData={commentData}
        isToday={isToday}
        user={user}
        advertisement={advertisement}
      />

      {!reviewSection && (
        <ShareModal
          showModal={showShareModal}
          setShowModal={setShowShareModal}
          shareData={advertisement.shareData}
        />
      )}
      <CreateAdModal
        showModal={showUpdateModal}
        setShowModal={setShowUpdateModal}
        handleSubmit={(values) => {
          updateAdvertisement(values);
          setShowUpdateModal(false);
        }}
        isSubmitting={isSubmitting}
        advertisement={advertisement}
        reviewSection={reviewSection}
      />
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        deleteAdvertisement={() => deleteAdvertisement(advertisement.id)}
        deleteReview={true}
      />
    </div>
  );
};

export default SingleAdvertisement;
