import HighlighterText from "react-highlight-words";
const Highlighter = (props) => {
    return (
        <HighlighterText
        highlightClassName="highlightKeyword"
        searchWords={props.searchKeyword ? [props.searchKeyword] : [""]}
        autoEscape={true}
        textToHighlight={props.highlightText}
        />
    )
}

export default Highlighter