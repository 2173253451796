//** Import Modules */
import { Helmet } from "react-helmet";

//** Import Components */
import PageTitle from "../../components/header/PageTitle";
import GroupListing from "./GroupListing";
import GroupSidebar from "./GroupSidebar";

export default function Group() {
  return (
    <div id='bizzll-page'>
      <Helmet>
        <title>Bizzll Groups</title>
        <meta name='description' content='This is the groups of Bizzll' />
      </Helmet>

      <PageTitle pageTitle='Groups' />

      <div id='bizzll-groups' className='page-with-sidebar'>
        <div className='inner-container'>
          <aside className='page-sidebar'>
            <GroupSidebar />
          </aside>
          <div className='page-content'>
            <GroupListing />
          </div>
        </div>
      </div>
    </div>
  );
}
