import { Select } from "antd";
const { Option } = Select;

const SelectDropdown = (props) => {
  return (
    <div>
      <Select
        key={props.key}
        className={props.className}
        value={props.value}
        defaultValue={props.defaultValue}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onSelect={props.onSelect}
        onBlur={props.onBlur}
        disabled={props.disabled}
        showSearch={props.showSearch ? props.showSearch : false}
        allowClear={props.allowClear ? props.allowClear : false}
        style={props.style}
        mode={props.mode}
      >
        {props.options &&
          props.options.map((option, index) => (
            <Option value={option.key} key={option.key}>
              {option.value}
              {option && option.type == "ticket" && (
                <strong>
                  {" "}
                  {"("}{" "}
                  {option.price && parseInt(option.price) != 0
                    ? `${"$" + option.price}`
                    : "Free"}{" "}
                  {")"}{" "}
                </strong>
              )}
            </Option>
          ))}
      </Select>
    </div>
  );
};

export default SelectDropdown;
