import { FlagFilled } from "@ant-design/icons";
import { Button, Form, Input, message, Modal, Radio, Space } from "antd";
import { useState } from 'react';
import axiosWithAuth from "../../utils/axiosWithAuth";



 const ReportModal = (props) => {
 const {
   isModalVisible,
   accusedId,
   user,
   adId,
   setIsModalVisible,
   type,
   option,
 } = props;

  const [reportInput, setReportInput] = useState("");
   const [value, setValue] = useState(1);
   const [submitBtnVisible, setSubmitBtnVisible] = useState(true);

    const onFinish = async (values) => {
      let reportObj = {};
      if (value == 5) {
        reportObj = {
          accused_id: accusedId, //person's id who gave comment
          accuser_id: user.id, //"login user's Id",
          type_id: adId,
          report_type: "others",
          report_extra: reportInput,
          is_settled: false,
          settled_date: null,
          notes: null,
          type: type.toLowerCase(),
        };
      } else {
        reportObj = {
          accused_id: accusedId,
          accuser_id: user.id, //"login user's Id",
          type_id: adId,
          report_type: value,
          report_extra: null,
          is_settled: false,
          settled_date: null,
          notes: null,
          type: type.toLowerCase(),
        };
      }

      const response = await axiosWithAuth().post(
        `${process.env.REACT_APP_BE_API_URL}/api/business/report`,
        reportObj
      );
      if (response && response.status == 200) {
        message.success(response.data.message);
        setIsModalVisible(false);
      }
    };


       const handleCancel = () => {
         setIsModalVisible(false);
       };

       const handleReportInput = (e) => {
         setReportInput(e.target.value);
       };
       const onChange = (e) => {
         setValue(e.target.value);
         setSubmitBtnVisible(false);
       };

       const Title = () => {
         return (
           <span className="reportBusinessTitle">
             <FlagFilled className="flagReportIcon" /> Report {type}
           </span>
         );
       };



  return (
    <Modal
      title={<Title />}
      visible={isModalVisible}
      footer={null}
      closable={false}
      onCancel={handleCancel}
    >
      <Form onFinish={onFinish}>
        <Form.Item
          name="comment_report"
          rules={
            !value && [
              {
                required: true,
                message: "Please Select a Problem!",
              },
            ]
          }
        >
          <span className="selectProblemText">Please Select a Problem</span>
          <Radio.Group
            onChange={onChange}
            value={value}
            className="radioGroupContainer"
          >
            <Space direction="vertical">
              {option?.map((elem, index) => (
                <Radio value={elem.value} key={index} className="reportType">
                  {elem.key}
                </Radio>
              ))}
              <Radio value={5} className="reportType">
                Other...
                {value === 5 ? (
                  <Input
                    required={true}
                    style={{ width: 100, marginLeft: 10 }}
                    onChange={handleReportInput}
                  />
                ) : null}
              </Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <div className="handleBusinessReportBtnContainer">
          <Form.Item>
            <Button type="default" onClick={handleCancel} size="large">
              Cancel
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="submitBusinessReportBtn"
              disabled={submitBtnVisible}
              size="large"
            >
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
}


export default ReportModal;