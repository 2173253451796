import React from "react";
import { Avatar, Modal } from "antd";

const LikeModal = (props) => {
  const { showModal, setShowModal, likeData } = props;
  return (
    <div>
      <Modal
        title={`Total ${likeData.length} likes`}
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={[]}
      >
        <div className="likes-container">
          {likeData.length
            ? likeData.map((like) => (
                <div className="likes-list">
                  <div>
                    <Avatar size={35}>{`${like.userInfo.user_fname
                      .substring(0, 1)
                      .toUpperCase()} ${like.userInfo.user_lname
                      .substring(0, 1)
                      .toUpperCase()}`}</Avatar>
                  </div>
                  <div className="like-username">
                    {`${like.userInfo.user_fname
                      .substring(0, 1)
                      .toUpperCase()}${like.userInfo.user_fname.substring(
                      1,
                      like.userInfo.user_fname.length
                    )} ${like.userInfo.user_lname
                      .substring(0, 1)
                      .toUpperCase()}${like.userInfo.user_lname.substring(
                      1,
                      like.userInfo.user_lname.length
                    )}`}
                  </div>
                </div>
              ))
            : "There is no like on this advertisement"}
        </div>
      </Modal>
    </div>
  );
};

export default LikeModal;
