import React, { useEffect, useState } from "react";
import axiosGeneral from "../../utils/axiosGeneral";
import ListingItem from "../Events/ListingItem";

export default function ProfileEventsPage(props) {
  const { business, propsData } = props;

  const [events, setEvents] = useState([]);

  useEffect(() => {
    const APIEndPoint = `/api/event/getEvents/${business.id}`;
    axiosGeneral()
      .get(APIEndPoint)
      .then((res) => {
        console.log(res);
        setEvents(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [business.id]);

  return (
    <div id='bizzll-events'>
      <div className='bizzll-events'>
        {events.map((event) => {
          return (
            <ListingItem key={event.id} event={event} propsData={propsData} />
          );
        })}
      </div>
    </div>
  );
}
