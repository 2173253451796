//** Import Modules */
import {
  faUser,
  faUserPlus,
  faUserTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Tooltip } from "antd";
import { useState } from "react";
//** Import Custom Hooks */
import { useBusinessFollow } from "../../../hooks/useBusinessFollow";

export default function FollowBusiness(props) {
  const { business, user, updateFollowerCount } = props;

  //* Set the fields data
  const [isFollowing, followBusiness, unFollowBusiness] = useBusinessFollow(
    user,
    business
  );

  //* Helper for the "Following" text for hover
  const [followingText, setFollowingText] = useState(
    <>
      <FontAwesomeIcon icon={faUser} /> Following
    </>
  );

  const hoverFollowingBtn = (state) => {
    if (state === 1) {
      setFollowingText(
        <>
          <FontAwesomeIcon icon={faUserTimes} /> Unfollow
        </>
      );
    } else {
      setFollowingText(
        <>
          <FontAwesomeIcon icon={faUser} /> Following
        </>
      );
    }
  };

  return (
    <div className='follow-btn'>
      {isFollowing ? (
        <Tooltip
          placement='top'
          title={user && user.id ? "" : "please login for follow the business"}
          color='#fb4400'
          key='#fb4400'
        >
          <Button
            onClick={(e) => {
              unFollowBusiness(e);
              updateFollowerCount("remove");
            }}
            title={`Unfollow ${business.name}`}
            onMouseOver={() => hoverFollowingBtn(1)}
            onMouseOut={() => hoverFollowingBtn(0)}
            type='primary'
            disabled={user && user.id ? false : true}
          >
            {followingText}
          </Button>
        </Tooltip>
      ) : (
        <Tooltip
          placement='top'
          title={user && user.id ? "" : "please login for follow the business"}
          color='#fb4400'
          key='#fb4400'
        >
          <Button
            onClick={(e) => {
              followBusiness(e);
              updateFollowerCount("add");
            }}
            title={`Follow ${business.name}`}
            disabled={user && user.id ? false : true}
            type='primary'
          >
            <FontAwesomeIcon icon={faUserPlus} style={{ marginRight: 10 }} />{" "}
            Follow
          </Button>
        </Tooltip>
      )}
    </div>
  );
}
