import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPen } from "@fortawesome/free-solid-svg-icons";
import { Button, Alert } from "antd";
import gsap from "gsap";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

//** Import Assets */
import cardIcons from "../../assets/images/card-icons.svg";
import { DASHBOARD_URL, FE_URL } from "../../common/constants";
import PaymentMethodItem from "./PaymentMethodItem";

export default function PaymentInformation(props) {
  const {
    user,
    currentStep,
    customerInfo,
    client_secret,
    checkoutType,
    alreadyActive,
  } = props;

  const stripe = useStripe();
  const elements = useElements();

  //* Set states
  const [collapse, setCollapse] = useState(false);
  const [divClass, setDivClass] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("new");

  //* Set the divs class
  useEffect(() => {
    let classNames;

    if (currentStep > 3) {
      classNames = "";
      setCollapse(true);

      gsap.to("#payment-information .step-content", {
        height: 0,
        padding: 0,
        duration: 0.3,
      });
    } else if (currentStep < 3) {
      classNames = "";
      setCollapse(true);

      gsap.to("#payment-information .step-content", {
        height: 0,
        padding: 0,
        duration: 0.3,
      });
    } else {
      classNames = "is-active incomplete";
      setCollapse(false);

      gsap.to("#payment-information .step-content", {
        height: "auto",
        padding: 10,
        duration: 0.3,
      });
    }

    setDivClass(classNames);
  }, [currentStep]);

  //* Options to modify the card elements styling
  const CARD_ELEMENT_OPTIONS = {
    showIcon: true,
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Nunito Sans", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "1.4rem",
      },
    },
  };

  //* Handle the payment form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    if (!stripe || !elements) {
      return;
    }

    let result;

    if (client_secret.type === "SetupIntent") {
      if (paymentMethod === "new") {
        result = await stripe.confirmCardSetup(client_secret.secret, {
          payment_method: {
            card: elements.getElement(CardNumberElement),
            billing_details: {
              name: `${user.fname} ${user.lname}`,
            },
          },
        });
      } else {
        result = await stripe.confirmCardSetup(client_secret.secret, {
          payment_method: {
            card: elements.getElement(CardNumberElement),
            billing_details: {
              name: `${user.fname} ${user.lname}`,
            },
          },
        });
      }
    } else {
      if (paymentMethod === "new") {
        result = await stripe.confirmCardPayment(client_secret.secret, {
          payment_method: {
            card: elements.getElement(CardNumberElement),
            billing_details: {
              name: `${user.fname} ${user.lname}`,
            },
          },
          return_url:
            checkoutType === "membership"
              ? `${DASHBOARD_URL}/account/membership`
              : `${DASHBOARD_URL}/account/billing-history`,
        });
      } else {
        result = await stripe.confirmCardPayment(client_secret.secret, {
          payment_method: paymentMethod,
          return_url:
            checkoutType === "membership"
              ? `${DASHBOARD_URL}/account/membership`
              : `${DASHBOARD_URL}/account/billing-history`,
        });
      }
    }

    if (result.error) {
      console.log("error", result.error.message);
      setIsSubmitting(false);
    } else {
      // The payment has been processed!
      if (result.paymentIntent && result.paymentIntent.status === "succeeded") {
        console.log("success", result);

        window.location.replace(`${FE_URL}/order-confirmation`);
      } else if (
        result.setupIntent &&
        result.setupIntent.status === "succeeded"
      ) {
        console.log("success", result);

        window.location.replace(`${FE_URL}/order-confirmation`);
      }
    }
  };

  return (
    <div
      id="payment-information"
      className={divClass ? `step-wrapper ${divClass}` : "step-wrapper"}
    >
      <div className="step-heading">
        <div className="icon">
          {collapse && currentStep > 2 ? (
            <FontAwesomeIcon icon={faCheck} />
          ) : (
            "3"
          )}
        </div>

        <div className="title">Payment Method</div>
      </div>

      <div className="step-content">
        <div className="card-details">
          <div className="label">Credit Card payment via Stripe</div>

          <div className="card-icons">
            <img src={cardIcons} alt="Accepted Cards" />
          </div>
        </div>

        {client_secret !== undefined || client_secret.secret !== "" ? (
          <div className="payment-method">
            {customerInfo.paymentMethods &&
            customerInfo.paymentMethods.data.length > 0 ? (
              <>
                {customerInfo.paymentMethods.data.map((item) => {
                  return (
                    <PaymentMethodItem
                      key={item.id}
                      data={item}
                      setPaymentMethod={setPaymentMethod}
                    />
                  );
                })}

                <div className="payment-method-item">
                  <label htmlFor="payment-method-new">
                    <input
                      type="radio"
                      id="payment-method-new"
                      value="new"
                      name="method[]"
                      onClick={() => setPaymentMethod("new")}
                    />{" "}
                    New Card
                  </label>
                </div>
              </>
            ) : (
              <></>
            )}
            {paymentMethod === "new" ? (
              <form onSubmit={handleSubmit}>
                <div className="fields">
                  <div className="card-element">
                    <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
                  </div>

                  <div className="card-element card-element-sm">
                    <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
                  </div>

                  <div className="card-element card-element-sm">
                    <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
                  </div>
                </div>

                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={alreadyActive || isSubmitting}
                  loading={isSubmitting}
                >
                  Process Order
                </Button>
              </form>
            ) : (
              <Button
                type="primary"
                disabled={alreadyActive || isSubmitting}
                loading={isSubmitting}
                onClick={handleSubmit}
              >
                Process Order
              </Button>
            )}
            {alreadyActive && (
              <Alert
                message="Already Active"
                description={
                  <>
                    The membership you are trying to buy is already active on
                    your account. If you would like to upgrade or cancel it,{" "}
                    <a href={`${DASHBOARD_URL}/account/membership`}>
                      click here
                    </a>{" "}
                    to go to your membership detials
                  </>
                }
                type="warning"
              />
            )}
          </div>
        ) : (
          "loading.."
        )}
      </div>
    </div>
  );
}
