//** Import Modules */
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPen } from "@fortawesome/free-solid-svg-icons";
import { Form, Button, Input } from "antd";
import { useHandleFormData } from "../../hooks/useHandleFormData";
import { userGeneralData } from "../../features/reducers/user";
import SelectDropdown from "../../components/common/SelectDropdown";
import gsap from "gsap";

export default function BillingInformation(props) {
  const { user, currentStep, setCurrentStep, userBillingInfo } = props;

  //* Set states
  const [isCompleted, setIsCompleted] = useState(false);
  const [divClass, setDivClass] = useState("");

  //* Set the divs class
  useEffect(() => {
    let classNames;

    if (currentStep > 2) {
      classNames = "";
      setIsCompleted(true);

      gsap.to("#billing-information .step-content", {
        height: 0,
        padding: 0,
        duration: 0.3,
      });
    } else {
      classNames = "is-active incomplete";
      setIsCompleted(false);

      gsap.to("#billing-information .step-content", {
        height: "auto",
        padding: 10,
        duration: 0.3,
      });
    }

    setDivClass(classNames);
  }, [currentStep]);

  //* Build the form
  const [form] = Form.useForm();

  const [
    fields,
    setFieldData,
    isSubmitting,
    submitForm,
    onFinishFailed,
    loadingForm,
  ] = useHandleFormData(user);

  useEffect(() => {
    if (user.id !== undefined) {
      setFieldData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div
      id='billing-information'
      className={divClass ? `step-wrapper ${divClass}` : "step-wrapper"}
    >
      <div className='step-heading'>
        <div className='icon'>
          {isCompleted ? <FontAwesomeIcon icon={faCheck} /> : "2"}
        </div>

        <div className='title'>Billing Information</div>

        {isCompleted && (
          <Button
            type='primary'
            icon={<FontAwesomeIcon icon={faPen} />}
            onClick={() => setCurrentStep(2)}
            className='edit-btn'
          />
        )}
      </div>

      {isCompleted && (
        <div className='step-summary'>
          <span className='sub-heading'>
            {userBillingInfo.billing_fname} {userBillingInfo.billing_lname}
          </span>
          <span>{userBillingInfo.billing_email}</span>
          <span>{userBillingInfo.billing_address}</span>
          <span>
            {userBillingInfo.billing_city}, {userBillingInfo.billing_state}{" "}
            {userBillingInfo.billing_zip}
          </span>
          <span>{userBillingInfo.billing_country}</span>
        </div>
      )}

      <div className='step-content'>
        {loadingForm ? (
          "Loading..."
        ) : (
          <Form
            size='large'
            layout='vertical'
            onFinish={(values) => {
              values.user_email = user.email;

              submitForm(values, `/api/users/user/update`, userGeneralData());

              setIsCompleted(true);
              setCurrentStep(3);
            }}
            onFinishFailed={onFinishFailed}
            initialValues={fields}
            form={form}
          >
            <div className='field-row'>
              <Form.Item
                label='First Name'
                name='meta_billing_fname'
                rules={[
                  {
                    required: true,
                    message: "Please input your First Name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label='Last Name'
                name='meta_billing_lname'
                rules={[
                  {
                    required: true,
                    message: "Please input your Last Name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>

            <Form.Item
              name='meta_billing_email'
              label='E-mail'
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name='meta_billing_address'
              label='Address'
              rules={[
                {
                  required: true,
                  message: "Please type an address!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name='meta_billing_address_2'
              label='Address 2'
              tooltip='(i.e. Suite #, Unit #)'
            >
              <Input />
            </Form.Item>

            <Form.Item
              name='meta_billing_city'
              label='City'
              rules={[
                {
                  required: true,
                  message: "Please type a city!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name='meta_billing_state'
              label='State'
              rules={[
                {
                  required: true,
                  message: "Please type a State!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label='ZIP Code'
              name='meta_billing_zip'
              rules={[
                {
                  required: true,
                  message: "Please input ZIP Code!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name='meta_billing_country'
              label='Country / Region'
              rules={[
                {
                  required: true,
                  message: "Please select Country / Region!",
                },
              ]}
            >
              <SelectDropdown
                placeholder='select your Country / Region'
                options={[{ key: "USA", value: "United States (US)" }]}
                value={form.getFieldValue("meta_billing_country")}
              />
            </Form.Item>

            <Form.Item>
              <Button
                type='primary'
                block
                htmlType='submit'
                loading={isSubmitting}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    </div>
  );
}
