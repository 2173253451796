//** Import Modules */
import axios from "axios";
import { getCookie } from "../common/manageCookies";

//** This component is meant to be used with secured API endpoint URL */
export default function axiosWithAuth() {
  //* Declare and specify the token to use
  const token = getCookie("ubt");

  return axios.create({
    baseURL: process.env.REACT_APP_BE_API_URL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
