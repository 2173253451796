import { Col, Radio, Row } from "antd";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useLocation } from "react-router-dom";
import LoadingComp from "../../components/common/LoadingComp";
import axiosGeneral from "../../utils/axiosGeneral";
import axiosWithAuth from "../../utils/axiosWithAuth";
import ListingItem from "./ListingItem";

export default function EventListing(props) {
  const {
    setCartData,
    cartData,
    setUpdateModalData,
    updateModalData,
    onlinePurchasedTickets,
    user,
  } = props;

  const [events, setEvents] = useState([]);
  const [eventsLength, setEventsLength] = useState(0);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoader, setIsLoader] = useState(false);
  const [filterCurrentPage, setFilterCurrentPage] = useState(1);
  const [sorting, setSorting] = useState("name");
  const [value, setValue] = useState("asc");
  const search = useLocation().search;
  //* Bring the user info
  const location = new URLSearchParams(search).get("location");
  const category = new URLSearchParams(search).get("category");
  const keyword = new URLSearchParams(search).get("keyword");
  let categoryQuery = "";
  let locationQuery = "";
  let eventNameQuery = "";

  if (location) {
    locationQuery = `&location=${location || ""}`;
  } else {
    locationQuery = "";
  }
  if (category) {
    categoryQuery = `&category=${category || ""}`;
  } else {
    categoryQuery = "";
  }
  if (keyword) {
    eventNameQuery = `&keyword=${keyword || ""}`;
  } else {
    eventNameQuery = "";
  }

  useEffect(() => {
    // Logic for checking if have an auth token
    const getToken = JSON.parse(localStorage.getItem("token"));
    if (getToken) {
      if ("token" in getToken) {
        setIsLoggedIn(true);
      }
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    setIsLoader(true);
    let searchFlag = false;

    let APIURL;

    if (location || category || keyword) {
      searchFlag = true;

      APIURL = `/api/event/listEvent?searchDataPage=${1}${locationQuery}${categoryQuery}&sort=${sorting}&OrderBy=${value}${eventNameQuery}`;
    } else {
      APIURL = `/api/event/listEvent?searchDataPage=${1}&sort=${sorting}&OrderBy=${value}`;
    }

    axiosGeneral()
      .get(APIURL)
      .then((res) => {
        if (res.status == 200 && res.data.events) {
          setEvents(res.data.events);
          setEventsLength(res.data.length);
          if (res.data.events.length == 0 || res.data.events.length < 12) {
            setLoading(false);
          }
          if (searchFlag) {
            setFilterCurrentPage(1);
          } else {
            setCurrentPage(1);
          }
          setUpdateModalData(res.data.events);
        }
        setIsLoader(false);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [search]);

  useEffect(() => {
    if (isLoggedIn && user && user.id) {
      getUserData();
    }
  }, [user]);

  const getUserData = async () => {
    const response = await axiosWithAuth().get(`/api/users/${user.id}`);
    if (response && response.status == 200) {
      setUserData(response.data);
    }
  };

  const loadMoreEvents = () => {
    let APIURL;

    if (location || category || keyword) {
      APIURL = `/api/event/listEvent?searchDataPage=${filterCurrentPage}${locationQuery}${categoryQuery}&sort=${sorting}&OrderBy=${value}${eventNameQuery}`;
    } else {
      APIURL = `/api/event/listEvent?searchDataPage=${currentPage}&sort=${sorting}&OrderBy=${value}`;
    }

    axiosGeneral()
      .get(APIURL)
      .then((res) => {
        if (res.status == 200 && res.data.events) {
          if (res.data.events.length == 0 || res.data.events.length < 12) {
            setLoading(false);
          }
          if (search) {
            setFilterCurrentPage(filterCurrentPage + 1);
          } else {
            setCurrentPage(currentPage + 1);
          }
          setEvents(events.concat(res.data.events));
          setEventsLength(res.data.length);
          setUpdateModalData(updateModalData.concat(res.data.events));
        }
        setIsLoader(false);
      })
      .catch((err) => {
        console.log(err.response);
      });
    // }
  };

  const handleSorting = (val) => {
    setLoading(true);
    setIsLoader(true);
    setSorting(val);
    setCurrentPage(1);
    if (search) {
      let SEARCH_DATA_API;

      if (location || category || keyword) {
        SEARCH_DATA_API = `${
          process.env.REACT_APP_BE_API_URL
        }/api/event/listEvent?searchDataPage=${1}${locationQuery}${categoryQuery}&sort=${val}&OrderBy=${value}${eventNameQuery}`;
      }
      axiosGeneral()
        .get(SEARCH_DATA_API)
        .then((res) => {
          setEvents(res.data.events);
          if (res.data.events.length == 0 || res.data.events.length < 12) {
            setLoading(false);
          }
          setCurrentPage(1);
          setUpdateModalData(res.data.events);
          setIsLoader(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const APIURL = `${
        process.env.REACT_APP_BE_API_URL
      }/api/event/listEvent?searchDataPage=${1}&sort=${val}&OrderBy=${value}`;

      axiosGeneral()
        .get(APIURL)
        .then((res) => {
          setEvents(res.data.events);
          setEventsLength(res.data.length);
          if (res.data.events.length == 0 || res.data.events.length < 12) {
            setLoading(false);
          }
          setFilterCurrentPage(1);
          setUpdateModalData(res.data.events);
          setIsLoader(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onChange = (e) => {
    setValue(e.target.value);
    setLoading(true);
    setIsLoader(true);
    setValue(e.target.value);
    setSorting(sorting || "name");
    if (search) {
      let SEARCH_DATA_API;

      if (location || category || keyword) {
        SEARCH_DATA_API = `${
          process.env.REACT_APP_BE_API_URL
        }/api/event/listEvent?searchDataPage=${1}${locationQuery}${categoryQuery}&sort=${sorting}&OrderBy=${
          e.target.value
        }${eventNameQuery}`;
      }
      axiosGeneral()
        .get(SEARCH_DATA_API)
        .then((res) => {
          if (res && res.status == 200) {
            setEvents(res.data.events);
            setEventsLength(res.data.length);
            if (res.data.events.length == 0 || res.data.events.length < 12) {
              setLoading(false);
            }
            setIsLoader(false);
            setFilterCurrentPage(1);
            setUpdateModalData(res.data.events);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const APIURL = `${
        process.env.REACT_APP_BE_API_URL
      }/api/event/listEvent?searchDataPage=${1}&sort=${sorting}&OrderBy=${
        e.target.value
      }`;
      axiosGeneral()
        .get(APIURL)
        .then((res) => {
          if (res && res.status == 200) {
            setEvents(res.data.events);
            setEventsLength(res.data.length);
            if (res.data.events.length == 0 || res.data.events.length < 12) {
              setLoading(false);
            }
            setUpdateModalData(res.data.events);
            setIsLoader(false);
            setCurrentPage(1);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  if (isLoader) {
    return <div className='loader' id='loader'></div>;
  } else {
    return (
      <div id='event-listing' className='listing-page'>
        <div className='listing-header'>
          <h4 className='results-num'>
            Found{" "}
            {events.length +
              ((currentPage + 1) * 12 - events.length) -
              ((currentPage + 1) * 12 - events.length)}{" "}
            of {eventsLength} Results
          </h4>

          <div className='sort-form'>
            <form>
              <label>
                <span>Sort By</span>
                <select
                  value={sorting}
                  onChange={(e) => handleSorting(e.target.value)}
                >
                  <option value='name'>Name</option>
                  <option value='date'>Date</option>
                </select>
              </label>
            </form>

            <Radio.Group onChange={onChange} value={value}>
              <Radio value='asc' className='radioVal'>
                ASC
              </Radio>
              <Radio value='desc' className='radioVal'>
                DESC
              </Radio>
            </Radio.Group>
          </div>
        </div>

        <InfiniteScroll
          pageStart={0}
          loadMore={loadMoreEvents}
          hasMore={loading}
          loader={<LoadingComp />}
        >
          <div className='bizzll-events'>
            {events.map((event) => {
              return (
                <ListingItem
                  key={event.id}
                  user={user}
                  event={event}
                  userData={userData}
                  setCartData={setCartData}
                  cartData={cartData}
                  onlinePurchasedTickets={onlinePurchasedTickets}
                />
              );
            })}
          </div>
        </InfiniteScroll>
      </div>
    );
  }
}
