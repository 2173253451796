import { Avatar } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

export default function ChatItem(props) {
  const { data, onClick } = props;

  const isToday = (someDate) => {
    const today = new Date();
    const someDateArray = someDate.split(" ")[0].split("-");
    return (
      someDateArray[2] == today.getDate() &&
      someDateArray[1] == today.getMonth() + 1 &&
      someDateArray[0] == today.getFullYear()
    );
  };

  return (
    <div className="chat-item" onClick={() => onClick()}>
      <div className="avatar">
        <Avatar size={40}>{`${data.senderInfo.user_fname
          .substring(0, 1)
          .toUpperCase()} ${data.senderInfo.user_lname
          .substring(0, 1)
          .toUpperCase()}`}</Avatar>
      </div>
      <div className="latest-message">
        <div className="sender-name-container">
          <div className="sender-name">
            {`${data.senderInfo.user_fname
              .substring(0, 1)
              .toUpperCase()}${data.senderInfo.user_fname.substring(
              1,
              data.senderInfo.user_fname.length
            )} ${data.senderInfo.user_lname
              .substring(0, 1)
              .toUpperCase()}${data.senderInfo.user_lname.substring(
              1,
              data.senderInfo.user_lname.length
            )}`}
          </div>
          <div
            className={
              data.isBusiness
                ? "message-type business-message"
                : "message-type private-message"
            }
          >
            {data.isBusiness ? "Business" : "Private"}
          </div>
        </div>
        <div className="latest-message-text">
          {data.latestMessage.is_deleted
            ? "This message has been deleted"
            : data.latestMessage.message.length > 20
            ? `${data.latestMessage.message.substring(0, 20)}...`
            : data.latestMessage.message}
        </div>
      </div>

      <div>
        <div className="message-time">
          {isToday(data.latestMessage.date_sent)
            ? moment.utc(data.latestMessage.date_sent).local().format("LT")
            : moment.utc(data.latestMessage.date_sent).local().format("L")}
        </div>
        {data.latestMessage.unread &&
        data.latestMessage.sender_id != data.id ? (
          <div>
            <FontAwesomeIcon
              icon={faCircle}
              color="#1890ff"
              className="unread-circle"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
