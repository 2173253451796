import React, { useState } from "react";
import { Modal } from "antd";
import ReactPlayer from "react-player";

export default function BizzllVideoPlayerModal(props) {
  const { source, buttonTxt } = props;

  const [openModal, setOpenModal] = useState(false);

  const showModal = () => {
    setOpenModal(true);
  };

  const hideModal = (ref) => {
    setOpenModal(false);

    const videoElm = document.querySelector(`.ant-modal-content video`);
    videoElm.pause();
  };

  return (
    <>
      <Modal
        visible={openModal}
        footer={null}
        onCancel={hideModal}
        bodyStyle={{ padding: 0 }}
        width={1200}
      >
        <iframe
          width="100%"
          height="500"
          src="https://www.youtube.com/embed/0FF039bQzTk?rel=0"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          style={{ margin: -"0 auto", maxWidth: 1200 }}
        ></iframe>
      </Modal>

      <button onClick={showModal}>{buttonTxt}</button>
    </>
  );
}
