const featuresData = [
  {
    label: "FREE DIRECTORY LISTING",
    toolTip: "BUSINESS OWNERS CAN REGISTER THEIR BUSINESS.",
    col1: "1",
    col2: "1",
    col3: "Up to 3",
  },
  {
    label: "FREE REGISTRATION",
    toolTip: "USERS WILL BE ABLE TO CREATE A FREE ACCOUNT.",
    col1: "yes",
    col2: "yes",
    col3: "yes",
  },
  {
    label: "VIEW FREE CONTENT",
    toolTip:
      "VIEW FREE CONTENT, LIKE EVENTS AND SEMINARS. YOU MUST HAVE AN ACCOUNT TO VIEW THE FREE CONTENT.",
    col1: "yes",
    col2: "yes",
    col3: "yes",
  },
  {
    label: "SHARE LINKS",
    toolTip: "SHARE LINKS OF CONTENT, VIDEO, EVENTS AND MUCH MORE.",
    col1: "yes",
    col2: "yes",
    col3: "yes",
  },
  {
    label: "RATE CONTENT",
    toolTip: "RATE CONTENT THAT YOU LIKE OR DON'T LIKE.",
    col1: "yes",
    col2: "yes",
    col3: "yes",
  },
  {
    label: "BUY TICKETS",
    toolTip:
      "BUY TICKETS TO VIEW EXCLUSIVE CONTENT, SUCH AS SEMINARS, EVENTS OR ONLINE CLASSES.",
    col1: "yes",
    col2: "yes",
    col3: "yes",
  },
  {
    label: "NETWORKING",
    toolTip:
      "NETWORK WITH OTHER BUSINESSES VIA EVENTS, SEMINARS OR OTHER ACTIVITIES. LEARN THE LATEST TRENDS AND SHARE YOUR EXPERIENCES..",
    col1: "no",
    col2: "yes",
    col3: "yes",
  },
  {
    label: "CREATE NETWORKING GROUPS",
    toolTip:
      "PROMOTE YOUR BUSINESS, SHARE BUSINESS EXPERIENCES OR BE UP TO DATE WITH THE LATEST INDUSTRY TRENDS.",
    col1: "3",
    col2: "5",
    col3: "10",
  },
  {
    label: "BUSINESS ADVERTISING",
    toolTip:
      "DISPLAY ADVERTISEMENTS OF YOUR BUSINESS ACROSS BIZZLL (REQUIRES A MINIMUM OF A NETWORKING BASIC MEMBERSHIP).",
    col1: "no",
    col2: "1",
    col3: "3",
  },
  {
    label: "RECEIVE PRIVATE MESSAGES",
    toolTip:
      "BUSINESSES CAN RECEIVE PRIVATE MESSAGES AND SEND MESSAGES USERS THAT FOLLOW THEM.",
    col1: "no",
    col2: "yes",
    col3: "yes",
  },
  {
    label: "SEND AUTOMATIC NOTIFICATIONS",
    toolTip: "NOTIFY YOUR FOLLOWERS OF YOUR NEW POSTS, PROMOTIONS OR EVENTS.",
    col1: "no",
    col2: "yes",
    col3: "yes",
  },
  {
    label: "RECEIVE FOLLOWERS AND FOLLOW YOUR FAVORITE BUSINESSES",
    toolTip: "FOLLOW YOUR FAVORITE BUSINESSES AND RECEIVE FOLLOWERS AS WELL.",
    col1: "no",
    col2: "yes",
    col3: "yes",
  },
  {
    label: "HAVE YOUR OWN BUSINESS PAGE",
    toolTip:
      "WITH A PAID MEMBERSHIP GET YOUR OWN BUSINESS PAGE TO POST MESSAGES, SPECIALS, CREATE EVENTS AND MUCH MORE.",
    col1: "no",
    col2: "yes",
    col3: "yes",
  },
  {
    label: "HAVE BIZZLL PROMOTE YOUR EVENTS",
    toolTip:
      "BIZZLL WILL HELP PROMOTE YOUR EVENTS THROUGHOUT  THE BIZZLL PLATFORM, IN SOCIAL MEDIA AND THROUGH E-MAIL.",
    col1: "no",
    col2: "yes",
    col3: "yes",
  },
  {
    label: "FEATURE LIVE EVENTS",
    toolTip:
      "BROADCAST LIVE EVENTS LIKE SEMINARS AND BUSINESS PROMOTIONS ANYTIME, ANYWHERE.",
    col1: "no",
    col2: "no",
    col3: "yes",
  },
];

module.exports = {
  featuresData,
};
