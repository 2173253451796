//** Import Modules */
import {
  faEnvelope,
  faMapMarkerAlt,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Skeleton, Image } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

//** Import Assets */
import defaultLogo from "../../assets/images/bizzll-icon.png";
import defaultCover from "../../assets/images/geopattern.png";
// import FollowBusiness from "./FollowBusiness";

export default function GroupHeader(props) {
  //* Bring the Props
  const {
    user,
    business,
    loading,
    baseURL,
    updateFollowerCount,
    openChatbox,
    setChatbox,
  } = props;

  //* Get the data to use for the profile
  const initialListingData = {
    logo: defaultLogo,
    coverImg: defaultCover,
    name: (
      <Skeleton.Input style={{ width: 200 }} active={true} size="default" />
    ),
    userName: <Skeleton.Avatar active={true} size="default" shape="circle" />,
    category: "",
    city: "",
    state: "",
    zip: "",
  };

  const [listingData, setListingData] = useState(initialListingData);

  useEffect(() => {
    if (!loading) {
      const prepareListingData = {
        ...listingData,
        id: business.id,
        name: business.name,
        userName: business.name,
        category: business.meta_data.business_category,
        city: business.meta_data.business_city,
        state: business.meta_data.business_state,
        zip: business.meta_data.business_zip,
        logo:
          (business.meta_data[1] && business.meta_data[1].meta_value) ||
          defaultLogo,
        coverImg:
          (business.meta_data[0] && business.meta_data[0].meta_value) ||
          defaultCover,
      };

      setListingData(prepareListingData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [business, loading]);

  // Build Profile URL
  const businessURL = `${baseURL}/${listingData.id}`;

  return (
    <header id="bizzll-profile-header" className="bizzll-profile-header">
      <div className="profile-header-cover ">
        <img src={listingData.coverImg} alt={`Cover for ${listingData.name}`} />
      </div>

      <div className="profile-header-content">
        <div className="profile-header-image">
          <Link to={businessURL}>
            <img src={listingData.logo} alt={`Logo for ${listingData.name}`} />
          </Link>
        </div>

        <div className="profile-meta">
          <div className="business-name">
            <h2>{listingData.name}</h2>
          </div>
        </div>

        <div className="networking">
          <div className="message-btn">
            <button
              onClick={() => {
                const chatboxes = openChatbox();
                setChatbox();
                window.localStorage.setItem(
                  "chatbox",
                  JSON.stringify(chatboxes)
                );
              }}
              title={`Message ${listingData.name}`}
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}
