import { AutoComplete, Select, Input } from "antd";
import React from "react";

export default function HomepageHeroSearch(props) {
  const { cities } = props;
  const { Option } = Select;

  const [keyword, setKeyword] = React.useState("");
  const [location, setLocation] = React.useState("");

  return (
    <div id='home-search'>
      <form method='get' action='/directory'>
        <div className='form-heading'>
          <span>Claim Your</span>
          Local Businesses
        </div>

        <label htmlFor='keyword' style={{ width: "100%" }}>
          <span>Find</span>
          <Input
            type='text'
            name='keyword'
            placeholder='Food, clothes, restaurants'
            onChange={(e) => setKeyword(e.target.value)}
            defaultValue={keyword ? keyword : ""}
          />
        </label>

        <label htmlFor='location'>
          <span>Near</span>
          <AutoComplete
            style={{
              width: "70%",
            }}
            placeholder={"City / ZIP code"}
            filterOption
            className='homeAutoSearch'
            notFoundContent={null}
            onChange={(value) => setLocation(value)}
            value={location}
          >
            {cities && cities.length
              ? cities.map((city) => {
                  return <Option key={city.name}>{city.name}</Option>;
                })
              : null}
          </AutoComplete>
        </label>

        <input type='hidden' name='location' value={location} />
        <button>Start Your Search</button>
      </form>
    </div>
  );
}
