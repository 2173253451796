//** Temp Assets */
import Advertisements from "../../components/ads/Advertisements";

export default function CitySidebar(props) {
  return (
    <>
      <div className='ad-space'>
        <Advertisements addLocation='citySidebar' type='skyscraper' />
      </div>
    </>
  );
}
