import React from "react";
import PageTitle from "../../components/header/PageTitle";
import TermsContent from "./TermsContent";

export default function Terms() {
  return (
    <div>
      <PageTitle pageTitle='Terms & Conditions' />

      <div className='page-content'>
        <div className='inner-container'>
          <TermsContent />
        </div>
      </div>
    </div>
  );
}
