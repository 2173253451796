//** Define Actions */
export const GET_EVENTS = "GET_EVENTS";
export const SET_EVENTS = "SET_EVENTS";

//** Create action functions */
export const getEvent = (eventID) => ({
  type: GET_EVENTS,
  eventID,
});

export const setEvent = (event) => ({
  type: SET_EVENTS,
  event,
});

//** Create initial state */
const initialState = {
  event: {
    name: "",
    business_id: 0,
    created: "",
    modified: "",
    owner_id: 0,
    meta_data: [],
    tickets: [],
    businessData: {
      meta_data: [],
    },
  },
};

//** Create the handler function */
const eventsReducer = (state = initialState, { type, event }) => {
  switch (type) {
    case SET_EVENTS:
      return {
        ...state,
        event,
      };
    default:
      return state;
  }
};

export default eventsReducer;
