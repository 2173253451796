//** Define Actions */
export const USER_GENERAL_DATA = "USER_GENERAL_DATA";
export const SET_USER_GENERAL_DATA = "SET_USER_GENERAL_DATA";

//** Create action functions */
export const userGeneralData = () => ({
  type: USER_GENERAL_DATA,
});

export const setUserGeneralData = (data) => ({
  type: SET_USER_GENERAL_DATA,
  data,
});

//** Create initial state */
const initialState = {
  user: {
    meta_data: [],
    referral: {},
  },
};

//** Create the handler function */
const userGeneralReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_USER_GENERAL_DATA:
      return {
        ...state,
        user: data,
      };
    default:
      return state;
  }
};

export default userGeneralReducer;
