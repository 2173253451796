//** Import Modules */
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

//** Import Components */
import BillingInformation from "./BillingInformation";
import PaymentInformation from "./PaymentInformation";
import axiosWithAuth from "../../utils/axiosWithAuth";
import FreeProductOrder from "./FreeProductOrder";

//* Get stripe promise
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export default function CheckoutSteps(props) {
  const {
    user,
    customerInfo,
    checkoutType,
    cartItems,
    subTotal,
    amountTotal,
    couponCode,
  } = props;

  //* Set states
  const [secret, setSecret] = useState("");
  const [currentStep, setCurrentStep] = useState(2);
  const [userBillingInfo, setUserBillingInfo] = useState({});
  const [subscription, setSubscription] = useState({});
  const [alreadyActive, setAlreadyActive] = useState(false);

  console.log("subscription", subscription, secret);

  //* If we already have billing info, move to step 3
  useEffect(() => {
    if (user.meta_data) {
      const billingInfo = user.meta_data
        .filter((item) => item.meta_key.includes("billing"))
        .map((item) => {
          return {
            [item.meta_key]: item.meta_value,
          };
        });

      let billingInfoObj;

      if (billingInfo && billingInfo.length > 3) {
        setCurrentStep(3);
        billingInfoObj = Object.assign({}, ...billingInfo);
      }

      setUserBillingInfo(billingInfoObj);
    }
  }, [user.meta_data]);

  //* Handle how to get the client_secret, depending on checkout type
  useEffect(() => {
    //Check if user already has a type of membership. If not, add it to the user. If yes, retrieve it.
    if (
      checkoutType === "membership" &&
      cartItems.length > 0 &&
      customerInfo.id
    ) {
      handleSubCreation();
    }

    // Create a payment intent if the purchase is a normal product
    if (
      (checkoutType !== "membership" || checkoutType === undefined) &&
      cartItems.length > 0 &&
      customerInfo.id &&
      subTotal > 0
    ) {
      console.log("Payment intent being created");
      createPaymentIntent();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerInfo]);

  //* Function to create a new subscription
  const handleSubCreation = async () => {
    const APIURL = `/api/stripe/customer/create/subscription`;

    let payload = {
      customerInfo,
      cartItems,
      subTotal,
      couponCode,
    };

    try {
      const getSubscription = await axiosWithAuth().post(APIURL, payload);

      setSubscription(getSubscription.data);

      let clientSecret;

      if (getSubscription.data.pending_setup_intent !== null) {
        const newAPIURL = `/api/stripe/checkout/setupIntent/${getSubscription.data.pending_setup_intent}`;

        const getSetupIntent = await axiosWithAuth().get(newAPIURL);

        clientSecret = {
          secret: getSetupIntent.data.client_secret,
          type: "SetupIntent",
        };
      } else {
        clientSecret = {
          secret:
            getSubscription.data.latest_invoice.payment_intent.client_secret,
          type: "PaymentIntent",
        };
      }

      setSecret(clientSecret);
    } catch (err) {
      console.log(err.response);
    }
  };

  //* Function to create a payment intent
  const createPaymentIntent = () => {
    const APIURL = `/api/stripe/checkout/paymentIntent`;

    let payload = {
      amount: amountTotal,
      cartItems: cartItems,
      customerID: customerInfo.id,
      subTotal: subTotal,
    };

    axiosWithAuth()
      .post(APIURL, payload)
      .then((res) => {
        setSecret(res.data.client_secret);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  return (
    <>
      <div className="step-wrapper">
        <div className="step-heading">
          <div className="icon">
            <FontAwesomeIcon icon={faCheck} />
          </div>

          <div className="title">
            Signed In
            <span>{user.email}</span>
          </div>
        </div>
      </div>

      <BillingInformation
        currentStep={currentStep}
        user={user}
        setCurrentStep={setCurrentStep}
        userBillingInfo={userBillingInfo}
      />

      {secret && secret !== "" ? (
        <Elements stripe={stripePromise}>
          <PaymentInformation
            customerInfo={customerInfo}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            user={user}
            client_secret={secret}
            checkoutType={checkoutType}
            alreadyActive={alreadyActive}
          />
        </Elements>
      ) : (
        <FreeProductOrder
          customerInfo={customerInfo}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          user={user}
          checkoutType={checkoutType}
          cartItems={cartItems}
          subTotal={subTotal}
          amountTotal={amountTotal}
        />
      )}
    </>
  );
}
