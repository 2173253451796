import React, { useEffect, useState } from "react";
import { BE_API_URL, FE_URL } from "../../common/constants";
import axiosWithAuth from "../../utils/axiosWithAuth";
import ZoomMtgEmbedded from "@zoomus/websdk/embedded";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

const client = ZoomMtgEmbedded.createClient();

export default function Zoom() {
  const URLQueryVars = new URL(document.location).searchParams;
  const token = URLQueryVars.get("t");

  const user = useSelector((state) => state.user.user);

  console.log("user", user);

  useEffect(() => {
    //This is gor testing
    const APIURL = "/api/event/online/verify/token";

    if (user.id) {
      axiosWithAuth()
        .post(APIURL, { token })
        .then((res) => {
          console.log(res);
          getSignature(res.data.pmi, res.data.passcode);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  }, [user]);

  const getSignature = (pmi, passcode) => {
    const APIURL = `${BE_API_URL}/api/zoom/auth`;

    const payload = {
      meetingNumber: pmi,
      role: 0,
    };

    axiosWithAuth()
      .post(APIURL, payload)
      .then((res) => {
        startMeeting(res.data.signature, pmi, passcode);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const startMeeting = (sig, pmi, passcode) => {
    const meetingData = {
      sdkKey: "S8ooDcylfIPs6lTcYlsCdlly0wGRhVaNBvKl",
      meetingNumber: pmi,
      password: passcode,
      role: 0,
      userName: `${user.fname} ${user.lname}`,
      userEmail: user.email,
      leaveUrl: `${FE_URL}`,
      registrantToken: "",
    };

    let meetingSDKElement = document.getElementById("meetingSDKElement");

    client.init({
      zoomAppRoot: meetingSDKElement,
      language: "en-US",
      customize: {
        meetingInfo: ["topic", "host", "participant", "dc", "enctype"],
        toolbar: {
          buttons: [
            {
              text: "Custom Button",
              className: "CustomButton",
              onClick: () => {
                console.log("custom button");
              },
            },
          ],
        },
        video: {
          isResizable: false,
          viewSizes: {
            default: {
              width: 1000,
              height: 600,
            },
          },
        },
      },
    });

    client.join({
      sdkKey: meetingData.sdkKey,
      signature: sig,
      meetingNumber: meetingData.meetingNumber,
      password: meetingData.password,
      userName: meetingData.userName,
    });
  };

  return (
    <div id="zoom-component">
      <Helmet>
        <style>{`header#site-header,footer {display: none;}`}</style>
      </Helmet>
      <main>
        <div id="meetingSDKElement"></div>
      </main>
    </div>
  );
}
