//** Import Modules */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faCalendarAlt,
  faStar,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import { Rate } from "antd";
import { useEffect, useState } from "react";
import axiosGeneral from "../../../src/utils/axiosGeneral";

export default function ProfileSubNav(props) {
  //* Get the props
  const { business, loading, baseURL, followerCount, hasBusinesses } = props;

  //* Build URL structure
  const permaLinkBase = `${baseURL}/${business.meta_data.business_handle}`;

  let [reviewAmount, setReviewAmount] = useState([]);
  const [avgReview, setAvgReview] = useState(0);

  useEffect(() => {
    if (!loading) {
      getAllReviewsOfBusiness();
    }
  }, [business, loading]);

  const getAllReviewsOfBusiness = async () => {
    await axiosGeneral().get(
      `${process.env.REACT_APP_BE_API_URL}/api/reviews/getAllReviews/${props.business.id}`
    ).then((res) => {
      if (res.data.length > 0) {
        let averageRating = 0;
        res.data.forEach((element) => {
          averageRating += element.rating
        })
        let rating = averageRating/res.data.length;
        setAvgReview(rating)
        setReviewAmount(res.data.length)
      }
    });
  };

  return (
    <div id='bizzll-profile-submenu' className='bizzll-profile-submenu'>
      <nav className='profile-submenu'>
        <ul>
          <li>
            <NavLink
              to={{
                pathname: `${permaLinkBase}`,
                query: hasBusinesses,
              }}
              className={({ isActive }) => (isActive ? "active" : undefined)}
              end
            >
              <div className='menu-icon'>
                <FontAwesomeIcon icon={faHome} />
              </div>
              <span>Home</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to={{
                pathname: `${permaLinkBase}/about`,
                query: hasBusinesses,
              }}
              className={({ isActive }) => (isActive ? "active" : undefined)}
              end
            >
              <div className='menu-icon'>
                <FontAwesomeIcon icon={faCircleInfo} />
              </div>
              <span>About</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to={{
                pathname: `${permaLinkBase}/events`,
                query: hasBusinesses,
              }}
              className={({ isActive }) => (isActive ? "active" : undefined)}
            >
              <div className='menu-icon'>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </div>
              <span>Events</span>
            </NavLink>
          </li>
        </ul>
      </nav>

      <NavLink
        to={{
          pathname: `${permaLinkBase}/reviews`,
          query: hasBusinesses,
        }}
        className={({ isActive }) => (isActive ? "active" : undefined)}
      >
        <div className='header-reviews'>
          <div className='stars'>
            { avgReview !== 0 &&
              <Rate
                defaultValue={avgReview}
                disabled={true}
                // style={{
                //   color: "#DC281E",
                // }}
                allowHalf
              /> 
            }
            { avgReview === 0 &&
              <Rate
                defaultValue={0}
                disabled={true}
                // style={{
                //   color: "#DC281E",
                // }}
                allowHalf
              />
            }
            
          </div>

          <span className='reviews-separator'>•</span>

          <div className='num-ratings'>{reviewAmount} Reviews</div>
        </div>
      </NavLink>

      <div className='follower-count'>
        <span>{followerCount}</span> Followers
      </div>
    </div>
  );
}
