//** Main Constants */

// FE URL
export const FE_URL = process.env.REACT_APP_FE_URL || "http://localhost:3000";

// BE URL
export const BE_API_URL = process.env.REACT_APP_BE_API_URL || "http://localhost:5000";

// DASHBOARD URL
export const DASHBOARD_URL = process.env.REACT_APP_DASHBOARD_URL || "http://localhost:3005";

// Misc
export const TIME_FORMAT = "hh:mm A";
