import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCcVisa } from "@fortawesome/free-brands-svg-icons";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";

export default function PaymentMethodItem({ data, setPaymentMethod }) {
  const [cardIcon, setCardIcon] = useState();

  useState(() => {
    const cardBrand = data.card.brand;

    if (cardBrand === "visa") {
      setCardIcon(faCcVisa);
    } else {
      setCardIcon(faCreditCard);
    }
  }, []);

  return (
    <div className="payment-method-item">
      <label htmlFor={`payment-method-${data.id}`}>
        <input
          type="radio"
          name="method[]"
          id={`payment-method-${data.id}`}
          value={data.id}
          onClick={() => setPaymentMethod(data.id)}
        />{" "}
        <FontAwesomeIcon icon={cardIcon} /> Ending in{" "}
        <strong>****{data.card.last4}</strong>{" "}
        <span>
          (Expires on {data.card.exp_month}/{data.card.exp_year})
        </span>
      </label>
    </div>
  );
}
