//** Import Modules */
import React, { useState, useEffect, useRef, useContext } from "react";
import { Form, Input, Button } from "antd";
import axiosWithAuth from "../../utils/axiosWithAuth";

//** Import Components */
import ChatBoxMessage from "./ChatBoxMessage";

//** Import Contexts */
import { SocketContext } from "../../contexts/SocketIO";

export default function ChatBox(props) {
  const { chatbox, closeChatbox } = props;

  const { sender, receiver, is_business } = chatbox;
  //* Bring the user info and socket
  const socket = useContext(SocketContext); 
  const [messages, setMessages] = useState([]);
  const [threadID, setThreadID] = useState(0);


  const messageForm = useRef(null);
  socket.on("groupMessage", function (messageData) {
    if (messageData.sender_id != sender.id ) {
      let data = [...messages,messageData]
      setMessages(data);
    }
    // if (messageData.recipient_id == sender.id ) {
    //   setMessages(messages.concat(messageData.data));
    // }
  });
  socket.on("delete-receiver-group-message", function (messageData) {
    if (messageData.thread_id == threadID ) {
      const newMessages = messages.map((message) => {
        if (message.id == messageData.id) {
          message.is_deleted = 1;
        }
        return message;
      });
      setMessages(newMessages);
    }
  });

  const deleteMessage = async (id) => {
    const APIURL = `/api/group/message/${id}`;
    const res = await axiosWithAuth().delete(APIURL);
    if (res.status == 204) {
      socket.emit(
        "delete-group-message",
        messages.find((message) => message.id == id)
      );
      const newMessage = messages.map((message) => {
        if (message.id == id) {
          return { ...message, is_deleted: true };
        }
        return message;
      });
      setMessages(newMessage);
    }
  };

  useEffect(() => {
  
    const APIURL = `/api/group/thread`;

    axiosWithAuth()
      .get(APIURL, {
        params: {
          group_id: receiver.id,
        },
      })
      .then((res) => {
        setMessages(res.data.messages);
        setThreadID(res.data.threadID);
        socket.emit("joinRoom", {username:sender.id,room:receiver.id});
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [sender.id, receiver.id]);

  const onFinish = (values) => {
    const APIURL = `/api/group/message`;

    const messageData = {
      thread_id: threadID,
      sender_id: sender.id,
      message: values.message,
      group_id: receiver.id,
    };

    axiosWithAuth()
      .post(APIURL, messageData)
      .then((res) => {
        const newMessage = [...messages, res.data];
        setMessages(newMessage);
        delete messageData.group_id;
        socket.emit("groupMessage", res.data);
        messageForm.current.resetFields();
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <div className="new-chatbox">
        <div className="chatbox-header">
          <div className="recipient-name">
            {sender.business_name}
          </div>

          <div
            className="close"
            onClick={() => {
              if (closeChatbox) {
                closeChatbox();
              }
            }}
          >
            x
          </div>
        </div>

        <div className="chatbox-body">
          <div className="messages-box">
            {messages.length > 0
              ? messages.map((message, index) => {
                  let sameSender;

                  if (messages[index - 1] !== undefined) {
                    sameSender =
                      messages[index - 1].sender_id === message.sender_id;
                  } else {
                    sameSender = false;
                  }
                  return (
                    <ChatBoxMessage
                      key={message.id}
                      message={message}
                      userID={sender.id}
                      sameSender={sameSender}
                      deleteMessage={deleteMessage}
                    />
                  );
                })
              : "No Messages Found"}
          </div>

          {/* <div className="send-message"> */}
            <Form
              name="basic"
              layout="horizontal"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              ref={messageForm}
            >
              <Form.Item
                name="message"
                rules={[{ required: true, message: "Please input a message!" }]}
              >
                <Input />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}
