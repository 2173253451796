import {
  faCheck,
  faQuestionCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "antd";
import { useEffect, useState } from "react";
import { featuresData } from "./PackagesFeaturesData";

//** Import Components */
import { Link } from "react-router-dom";
import { DASHBOARD_URL } from "../../common/constants";

export default function HomepagePackages() {
  // State of current package period
  const [packagePeriod, setPackagePeriod] = useState("monthly");

  // Function to convert text to icon
  const convertIcon = (info) => {
    if (info === "yes") {
      return <FontAwesomeIcon icon={faCheck} className='yes' />;
    } else if (info === "no") {
      return <FontAwesomeIcon icon={faTimes} className='no' />;
    } else {
      return <span className='info'>{info}</span>;
    }
  };

  // Components for prices
  const NetworkingBasicPrice = () => {
    return packagePeriod === "monthly" ? (
      <>
        <span className='amount'>
          $19.<sup>99USD</sup>
        </span>
      </>
    ) : (
      <>
        <span className='old-price'>$239.88</span>
        <span className='amount'>
          $191.<sup>99USD</sup>
        </span>
      </>
    );
  };

  const NetworkingPlusPrice = () => {
    return packagePeriod === "monthly" ? (
      <>
        <span className='amount'>
          $49.<sup>99USD</sup>
        </span>
      </>
    ) : (
      <>
        <span className='old-price'>$599.88</span>
        <span className='amount'>
          $479.<sup>99USD</sup>
        </span>
      </>
    );
  };

  // Component for label
  const FeatureLabel = ({ toolTip, label }) => {
    return (
      <Tooltip title={toolTip} placement='top' color='#fb4400'>
        <span>
          <FontAwesomeIcon icon={faQuestionCircle} className='help-icon' />
          {label}:
        </span>
      </Tooltip>
    );
  };

  // Component for mobile feature table
  const MobileFeatureTableRow = ({ item, itemVal }) => {
    if (itemVal === "no") {
      return null;
    }

    return (
      <div className='table-row'>
        <div className='label col'>
          <FeatureLabel toolTip={item.toolTip} label={item.label} />
        </div>

        <div className='col'>{convertIcon(itemVal)}</div>
      </div>
    );
  };

  return (
    <div id='packages'>
      <h2>Our Packages</h2>

      <div className='comparison-table'>
        <div className='table-row price-row'>
          <div className='label'>
            <button
              className={packagePeriod === "monthly" ? "active monthly" : "monthly"}
              onClick={() => {
                setPackagePeriod("monthly");
              }}
            >
              Monthly
            </button>
            <button
              className={packagePeriod === "yearly" ? "active annual" : "annual"}
              onClick={() => {
                setPackagePeriod("yearly");
              }}
            >
              Annual
            </button>
          </div>

          <div className='price-col'>
            <div className='price'>
              <NetworkingBasicPrice />
            </div>
          </div>

          <div className='price-col'>
            <div className='price'>
              <NetworkingPlusPrice />
            </div>
          </div>
        </div>

        <div className='features-table mobile-table'>
          <div className='features-tier-1 features-col'>
            <h3>Free</h3>

            {featuresData.map((item, index) => {
              return (
                <MobileFeatureTableRow
                  key={index}
                  item={item}
                  itemVal={item.col1}
                />
              );
            })}

            <a href={`${DASHBOARD_URL}/?action=register`}>Get Started</a>
          </div>

          <div className='features-tier-1 features-col'>
            <h3>
              Networking Basic
              <span className='price'>
                <NetworkingBasicPrice />
              </span>
            </h3>

            {featuresData.map((item, index) => {
              return (
                <MobileFeatureTableRow
                  key={index}
                  item={item}
                  itemVal={item.col2}
                />
              );
            })}

            <a href='/checkout/?type=membership&membershipID=1'>Get Started</a>
          </div>

          <div className='features-tier-1 features-col'>
            <h3>
              Networking Plus
              <span className='price'>
                <NetworkingPlusPrice />
              </span>
            </h3>

            {featuresData.map((item, index) => {
              return (
                <MobileFeatureTableRow
                  key={index}
                  item={item}
                  itemVal={item.col3}
                />
              );
            })}

            <a href='/checkout/?type=membership&membershipID=2'>Get Started</a>
          </div>
        </div>

        <div
          className='table-row heading-row desktop-table
        '
        >
          <div className='label col col-head-first'>
            <h3>Main Features</h3>
          </div>
          <div className='col col-head-midle'>
            <h3>Free</h3>
          </div>

          <div className='col col-head-midle'>
            <h3>Networking Basic</h3>
          </div>

          <div className='col col-head-last'>
            <h3>Networking Plus</h3>
          </div>
        </div>

        {featuresData.map((feature, index) => {
          return (
            <div className='table-row desktop-table' key={index}>
              <div className='label col col-body'>
                <Tooltip
                  title={feature.toolTip}
                  placement='top'
                  color='#fb4400'
                >
                  <span>
                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      className='help-icon'
                    />
                    {feature.label}:
                  </span>
                </Tooltip>
              </div>

              <div className='col col-body'>{convertIcon(feature.col1)}</div>

              <div className='col col-body'>{convertIcon(feature.col2)}</div>

              <div className='col col-body'>{convertIcon(feature.col3)}</div>
            </div>
          );
        })}

        <div className='table-row footer-row desktop-table'>
          <div className='label col col-head-first'></div>
          <div className='col col-head-midle'>
            <div className='start-now'>
              <a href={`${DASHBOARD_URL}/?action=register`}>Get Started</a>
            </div>
          </div>

          <div className='col col-head-midle'>
            <div className='start-now'>
              <a href='/checkout/?type=membership&membershipID=1'>
                Get Started
              </a>
            </div>
          </div>

          <div className='col col-head-last'>
            <div className='start-now'>
              <a href='/checkout/?type=membership&membershipID=2'>
                Get Started
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
