//** Import Modules */
import { call, put } from "redux-saga/effects";
import { setEvent } from "../../reducers/event";
import {
  requestGetEvent,
  requestGetEventBusiness,
  requestGetEventsTickets,
} from "../requests/event";

export function* handleGetEvent({ eventID }) {
  try {
    //* Get the Event Data
    const responseGetEvent = yield call(requestGetEvent, eventID);
    const responseGetEventTickets = yield call(
      requestGetEventsTickets,
      eventID
    );

    const eventMetaData = responseGetEvent.data.meta_data.map((data) => {
      return {
        [`${data.meta_key}`]: data.meta_value,
      };
    });

    //* Get the business data
    const requestBusinessData = yield requestGetEventBusiness(
      responseGetEvent.data.business_id
    );

    const businessMetaData = requestBusinessData.data.meta_data.map((data) => {
      return {
        [`${data.meta_key}`]: data.meta_value,
      };
    });

    const businessData = {
      ...requestBusinessData.data,
      meta_data: Object.assign({}, ...businessMetaData),
    };

    //* Prepare full event data
    const event = {
      ...responseGetEvent.data,
      meta_data: Object.assign({}, ...eventMetaData),
      businessData: businessData,
      tickets: responseGetEventTickets.data.map((ticket) => {
        return {
          ...ticket,
          key: ticket.id,
        };
      }),
    };

    yield put(setEvent(event));
  } catch (err) {
    console.log(err.response);
  }
}
