//** Import Modules */
import axiosWithAuth from "../../../utils/axiosWithAuth";

export function requestGetUser() {
  return axiosWithAuth().request({
    method: "get",
    url: `${process.env.REACT_APP_BE_API_URL}/api/users/user/all`,
  });
}

export function requestGetUserRole() {
  return axiosWithAuth().request({
    method: "get",
    url: `${process.env.REACT_APP_BE_API_URL}/api/users/roleAccess`,
  });
}

export function requestGetReferralInfo(code) {
  return axiosWithAuth().request({
    method: "get",
    url: `${process.env.REACT_APP_BE_API_URL}/api/users/referrals/general/${code}`,
  });
}
