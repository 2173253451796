//** Import Components */
import { Divider } from "antd";
import React from "react";
import { DASHBOARD_URL, FE_URL } from "../../common/constants";

export default function HeaderUserMenu({ user }) {
  const roleCapabilities = user.role ? user.role.capabilities : [];

  const logUserOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("chatbox");
    localStorage.removeItem("ticketData");
  };

  return (
    <div className='header-user-menu'>
      <ul>
        <li>
          <a href={`${DASHBOARD_URL}/account/`}>User Dashboard</a>
        </li>
        <li>
          <a href={`${DASHBOARD_URL}/account/my-businesses`}>My Businesses</a>
        </li>
        {roleCapabilities.includes("access_affiliate") && (
          <li>
            <a href={`${DASHBOARD_URL}/affiliate/`}>Affiliate Dashboard</a>
          </li>
        )}
        {roleCapabilities.includes("access_marketer") && (
          <li>
            <a href={`${DASHBOARD_URL}/affiliate/`}>Marketer Dashboard</a>
          </li>
        )}
      </ul>

      <Divider style={{ margin: 0 }} />

      <ul>
        <li>
          <a
            href={`${DASHBOARD_URL}/logout?redirect=${FE_URL}`}
            onClick={logUserOut}
          >
            Logout
          </a>
        </li>
      </ul>
    </div>
  );
}
