import { Button, Form, Input, message, Modal } from "antd";
import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axiosGeneral from "../../utils/axiosGeneral";

const SupportEmailModal = (props) => {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const recaptchaRef = useRef(null);
  const {
    title,
    supportBizzllModal,
    setSupportBizzllModal,
    setbackModal,
    loadingBtn,
    setLoadingBtn,
    businessId,
    eventName,
    ticketName,
    emailtype,
    userId,
  } = props;
  const [isValidCaptcha, setIsValidCaptcha] = useState("");

  const handleOnChange = (value) => {
    if (value) {
      setIsValidCaptcha("");
    }
  };
  return (
    <Modal
      centered
      title={title}
      visible={supportBizzllModal}
      onOk={() => setSupportBizzllModal(false)}
      onCancel={() => setSupportBizzllModal(false)}
      footer={null}
    >
      <Form
        form={form}
        autoComplete="off"
        onFinish={async (values) => {
          setLoadingBtn(true);
          const captchaToken = await recaptchaRef.current.getValue();
          recaptchaRef.current.reset();
          const APIURL = `/api/business/claimbusinessVerification`;
          await axiosGeneral()
            .post(APIURL, {
              emailtype: emailtype,
              businessId: businessId && businessId,
              userId: userId && userId,
              userName: values.name,
              emailId: values.email,
              message: values.message,
              eventName: eventName && eventName,
              ticketName: ticketName && ticketName,
              captchaToken,
            })
            .then((response) => {
              if (response?.status == 200) {
                Modal.success({
                  title: "Request Sent",
                  content: response.data.message,
                });

                setLoadingBtn(false);
                setSupportBizzllModal(false);
                if (setbackModal) {
                  setbackModal(false);
                }
              }
            })
            .catch((error) => {
              setIsValidCaptcha(error.response.data.message);
              setLoadingBtn(false);
            });
        }}
      >
        <Form.Item
          label="Business Owner's Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input your name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Business Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your Email!",
            },
            {
              type: "email",
              message: "This is not a valid email!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Message"
          name="message"
          rules={[
            {
              required: true,
              message: "Please input message!",
            },
          ]}
        >
          <TextArea />
        </Form.Item>

        <h4>
          BIZZLL Will provide a one-time code by calling the business telephone
          number on file. A follow up email will be sent to you with
          instructions to enter the one-time code to claim your business.
          <br />
          <br />
          Thank you,
          <br />
          The BIZZLL Team
        </h4>

        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY}
          size="normal"
          className="g-recaptcha"
          onChange={handleOnChange}
        />
        {isValidCaptcha && (
          <h4
            style={{
              color: "#F00000",
            }}
          >
            {isValidCaptcha}
          </h4>
        )}
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loadingBtn}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default SupportEmailModal;
