import Advertisements from "./Advertisements/Advertisements";

export default function ProfileMainPage(props) {
  return (
    <div>
      <Advertisements
        business={props.business}
        user={props.user}
        propsData={props.propsData}
      />
    </div>
  );
}
