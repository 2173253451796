import { Button, Form, Input, Modal } from "antd";
import UploadMedia from "./UploadMedia";

const CreatePostModal = (props) => {
  const [form] = Form.useForm();

  const { showModal, setShowModal, handleSubmit, isSubmitting, advertisement } =
    props;

  return (
    <div>
      <Modal
        title={advertisement ? "Update Post" : "Create an Post"}
        visible={showModal}
        onCancel={() => {
          setShowModal(false);
          form.resetFields();
        }}
        footer={[]}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            text: advertisement ? advertisement.text : "",
            attachments: advertisement ? advertisement.attachments : "",
          }}
          onFinish={async (values) => {
            const formData = new FormData();
            formData.append("file", values.attachments);
            await handleSubmit({ formData, values });
            if (!advertisement) {
              form.resetFields();
            }
          }}
          onFinishFailed={() => console.log("failed")}
          autoComplete="off"
        >
          <Form.Item
            label="Text"
            name="text"
            rules={[
              {
                required: true,
                message: "Please enter text for your advertisement",
              },
            ]}
          >
            <Input.TextArea disabled={isSubmitting} />
          </Form.Item>
          <Form.Item label="Choose attachment" name="attachments">
            <UploadMedia
              setMedia={(file) =>
                form.setFieldsValue({
                  ...form.getFieldsValue(),
                  attachments: file,
                })
              }
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
              className="add-post-btn btn-left"
            >
              {isSubmitting
                ? "Submitting"
                : advertisement
                ? "Update"
                : "Submit"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default CreatePostModal;
