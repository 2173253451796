import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import LoadingComp from "../../components/common/LoadingComp";
import axiosGeneral from "../../utils/axiosGeneral";
import ListingItem from "./ListingItem";

export default function CityListing() {
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(true);
  const search = useLocation().search;

  //* Bring the user info
  const location = new URLSearchParams(search).get("location");

  useEffect(() => {
    let APIURL;
    if (location) {
      APIURL = `/api/city/list?location=${location || ""}`;
    } else {
      APIURL = "/api/city/getAllCities";
    }
    axiosGeneral()
      .get(APIURL)
      .then((res) => {
        if (res.status === 200 && res.data.length > 0) {
          setCities(res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [location, search]);

  return (
    <div id='cities-listing'>
      {loading ? (
        <LoadingComp />
      ) : (
        cities.map((city) => {
          return <ListingItem key={city.id} city={city} />;
        })
      )}
    </div>
  );
}
