// This hook will be to manage the following system

//** Import Modules */
import { useContext, useEffect, useState } from "react";
import axiosWithAuth from "../utils/axiosWithAuth";

//** Import Contexts */
import { SocketContext } from "../contexts/SocketIO";

export const useBusinessFollow = (user, business) => {
  //** Set the state for when the user is following */
  const [isFollowing, setIsFollowing] = useState(false);

  //** Specify the business and user id */
  const userID = user.id;
  const businessID = business.id;
  const businessInfo = business;

  //** Bring in the socket connection
  const socket = useContext(SocketContext);

  //* Let's check if the current user is following the business
  useEffect(() => {
    if (user?.id && business?.id) {
      axiosWithAuth()
        .get(`/api/business/${business.id}/follower`)
        .then((res) => {
          if (res.data.length > 0) {
            setIsFollowing(true);
          } else {
            setIsFollowing(false);
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  }, [business.id, user.id]);

  //** Follow Business */
  const followBusiness = (e) => {
    e.preventDefault();

    const followData = {
      user_id: userID,
      owner_id: business.owner_id,
    };

    const businessId = businessID;

    axiosWithAuth()
      .post(`/api/business/${businessId}/follow`, followData)
      .then((res) => {
        setIsFollowing(true);
        if (res.data.shouldNotify) {
          socket.emit("send-notification", businessInfo, "follow");
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  //** Unfollow Business */
  const unFollowBusiness = (e) => {
    e.preventDefault();

    const followData = {
      user_id: userID,
    };

    const businessId = businessID;

    axiosWithAuth()
      .delete(`/api/business/${businessId}/follow`, { data: followData })
      .then((res) => {
        setIsFollowing(false);
        socket.emit("send-notification", businessInfo, "unfollow");
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  // Return all necessary functions/states
  return [isFollowing, followBusiness, unFollowBusiness];
};
