//** Import Modules */
import { call, put } from "redux-saga/effects";
import { setUserMessages } from "../../reducers/messages";
import {
    requestGetUserMessages
} from "../requests/messages";

export function* handleGetUserMessage(value) {
    try {
        const { user_id } = value;
        const response = yield call(requestGetUserMessages, user_id);

        yield put(setUserMessages(response.data.messageList));
    } catch (err) {
        console.log(err.response);
    }
}