// This is a custom hook to gather the data for the forms to populate fields

//** Import Modules */
import { useState } from "react";
import moment from "moment";
import axiosWithAuth from "../utils/axiosWithAuth";
import { Modal } from "antd";
import { useDispatch } from "react-redux";

export const useHandleFormData = (data) => {
  const [fields, setFields] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loadingForm, setLoadingForm] = useState(true);
  const dispatch = useDispatch();

  //* Format the field data and return it to be used for the form
  const setFieldData = (fieldIsArray) => {
    let formFieldsVal = {};

    Object.keys(data).map((key) => {
      if (key === "meta_data") {
        Object.keys(data.meta_data).map((metaKey) => {
          if (data.meta_data[metaKey].meta_key.includes("date")) {
            formFieldsVal = {
              ...formFieldsVal,
              [`meta_${data.meta_data[metaKey].meta_key}`]: moment(
                data.meta_data[metaKey].meta_value
              ),
            };
          } else if (data.meta_data[metaKey].meta_key.includes("time")) {
            formFieldsVal = {
              ...formFieldsVal,
              [`meta_${data.meta_data[metaKey].meta_key}`]: moment(
                data.meta_data[metaKey].meta_value,
                "HH:mm"
              ),
            };
          } else if (
            fieldIsArray !== undefined &&
            fieldIsArray.includes(`meta_${data.meta_data[metaKey].meta_key}`)
          ) {
            const valueAsArray = data.meta_data[metaKey].meta_value.split(",");

            formFieldsVal = {
              ...formFieldsVal,
              [`meta_${data.meta_data[metaKey].meta_key}`]:
                valueAsArray.length > 0 ? valueAsArray : [],
            };
          } else {
            formFieldsVal = {
              ...formFieldsVal,
              [`meta_${data.meta_data[metaKey].meta_key}`]:
                data.meta_data[metaKey].meta_value,
            };
          }

          return formFieldsVal;
        });
      } else {
        formFieldsVal = {
          ...formFieldsVal,
          [`${key}`]: data[key],
        };

        return formFieldsVal;
      }

      return formFieldsVal;
    });

    setFields(formFieldsVal);
    setLoadingForm(false);
  };

  const handleImageUpload = async (key, value) => {
    let APIURL = `${process.env.REACT_APP_BE_API_URL}/api/common/uploadImage/businesses-${data.id}`;

    const config = {
      headers: {
        "content-type": `multipart/form-data`,
      },
    };

    const formData = new FormData();

    formData.append("file", value);

    let imgURL;

    try {
      const imgURLReq = await axiosWithAuth().post(
        `${APIURL}/${key}_${new Date().getTime()}`,
        formData,
        config
      );

      imgURL = imgURLReq.data.url;
    } catch (err) {
      console.log("Error handling image upload", err.response);

      return err;
    }

    return imgURL;
  };

  //* Submit the form
  const submitForm = async (
    formData,
    APIendpoint,
    dispatchFunction,
    useModal
  ) => {
    setIsSubmitting(true);

    let formattedData = {
      meta_data: [],
    };

    await Promise.all(
      Object.keys(formData).map(async (key) => {
        if (key.includes("meta")) {
          // Initialize the obj. Remove the "meta_" from string
          let metaObj = {
            meta_key: key.replace("meta_", ""),
            meta_value: formData[key] === undefined ? "" : formData[key],
          };

          // Adjust the date values
          if (key.includes("date")) {
            metaObj.meta_value = moment(metaObj.meta_value).format(
              "YYYY-MM-DD HH:mm:ss"
            );
          } else if (key.includes("open_time") || key.includes("close_time")) {
            metaObj.meta_value = moment(metaObj.meta_value).format("HH:mm");
          }

          // Check if we need to upload an image
          if (
            formData[key] &&
            formData[key].type &&
            formData[key].type.includes("image")
          ) {
            const imgURL = await handleImageUpload(key, formData[key]);

            metaObj.meta_value = imgURL;
          }

          return (formattedData = {
            ...formattedData,
            meta_data: [...formattedData["meta_data"], metaObj],
          });
        } else {
          return (formattedData = {
            ...formattedData,
            [key]: formData[key],
          });
        }
      })
    );

    axiosWithAuth()
      .put(APIendpoint, formattedData)
      .then(() => {
        if (useModal) {
          const modal = Modal.success({
            title: "Settings Saved",
            content: `Settings were saved successfully`,
          });

          setTimeout(() => {
            modal.destroy();
          }, 5000);
        }

        if (dispatchFunction && dispatchFunction !== undefined) {
          dispatch(dispatchFunction);
        }

        setIsSubmitting(false);
      })
      .catch((err) => {
        console.log(err.response);

        const modal = Modal.error({
          title: "Error Saving Business",
          content: err.message,
        });

        setTimeout(() => {
          modal.destroy();
        }, 5000);

        setIsSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // Return all necessary functions/states
  return [
    fields,
    setFieldData,
    isSubmitting,
    submitForm,
    onFinishFailed,
    loadingForm,
  ];
};
