//** Import Modules */
import { Skeleton } from "antd";
import { useEffect, useState } from "react";
import { Route, Routes, useParams } from "react-router-dom";

//** Import Assets */
import "../../assets/css/Profile.css";

//** Import Components */
import ChatBoxContainer from "../../components/GroupChatBox/ChatBoxContainer";
import { useSelector } from "react-redux";
import { useOpenChatbox } from "../../hooks/useOpenChatbox";
import axiosWithAuth from "../../utils/axiosWithAuth";
import GroupHeader from "./GroupHeader";
import GroupMainPage from "./GroupMainPage";

export default function Group(props) {
  //* Get the params from the route
  const routeParams = useParams();
  //* Bring the user info
  const user = useSelector((state) => state.user.user);

  //* Get the base URL for the profile pages
  const baseURL = "/group";

  //* Get The Business Data
  const [group, setGroup] = useState({});
  const [loading, setIsLoading] = useState(true);
  const [userBlocked, setUserBlocked] = useState(false);
  const [chatbox, setChatbox] = useState();

  console.log("group", group);

  useEffect(() => {
    const chatbox = JSON.parse(window.localStorage.getItem("chatbox"));
    if (chatbox) {
      setChatbox(chatbox);
    }
  }, []);

  //* Get the chatbox hook
  const [chatboxes, openChatbox, minimizeChatbox, closeChatbox] =
    useOpenChatbox(user, group, false);

  useEffect(() => {
    if (routeParams.id) {
      const APIURL = `/api/group/getGroupById/${routeParams.id}`;
      axiosWithAuth()
        .get(APIURL)
        .then((res) => {
          setGroup(res.data);

          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  }, [routeParams.id]);

  //* Get the total number of followers
  const [followerCount, setFollowerCount] = useState(0);

  const updateFollowerCount = (operation) => {
    if (operation === "add") {
      setFollowerCount(followerCount + 1);
    } else {
      setFollowerCount(followerCount - 1);
    }
  };

  return (
    <div id="bizzll-business-profile">
      <GroupHeader
        user={user}
        business={group}
        loading={loading}
        baseURL={baseURL}
        updateFollowerCount={updateFollowerCount}
        openChatbox={openChatbox}
        chatboxes={chatboxes}
        setChatbox={setChatbox}
      />

      <div className="profile-inner">
        {!loading ? (
          <>
            <div id="profile-page-content">
              <div className="bizzll-group-page-inner">
                <Routes>
                  <Route
                    element={
                      <GroupMainPage
                        group={group}
                        user={user}
                        propsData={props}
                        userBlocked={userBlocked}
                      />
                    }
                    path={`${baseURL}/${routeParams.id}`}
                    exact
                  />
                </Routes>
              </div>
            </div>
          </>
        ) : (
          <Skeleton active />
        )}
      </div>

      <ChatBoxContainer
        chatboxes={chatbox || chatboxes}
        closeChatbox={closeChatbox}
      />
    </div>
  );
}
