import { Button, message, Tooltip } from "antd";
import { useEffect, useState } from "react";
import "../../assets/css/ProfileReviewsPage.css";
import axiosGeneral from "../../utils/axiosGeneral";
import axiosWithAuth from "../../utils/axiosWithAuth";
import CreateAdModal from "../Profile/Advertisements/CreateAdModal";
import SingleAdvertisement from "./Advertisements/SingleAdvertisement";

export default function ProfileReviewsPage(props) {
  const { business, user } = props;

  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [review, setReview] = useState([]);
  const [reviewed, setReviewed] = useState(false);

  useEffect(() => {
    getAllReviewsOfBusiness();
  }, []);

  useEffect(() => {
    if (review.length > 0) {
      review.forEach((data) => {
        if (data.user_id === props.user.id) {
          setReviewed(true);
        }
      });
    }
  }, [review]);

  const getAllReviewsOfBusiness = async () => {
    const response = await axiosGeneral().get(
      `${process.env.REACT_APP_BE_API_URL}/api/reviews/getAllReviews/${props.business.id}`
    );
    if (response.status === 200) {
      setReview(response.data.reverse());
    }
  };

  const handleReplyReview = async (id, data) => {
    data.review_id = id;
    data.action = "reply";

    const dataPost = {'data' : {...data}}

    try {
      await axiosWithAuth().post(
        `${process.env.REACT_APP_BE_API_URL}/api/reviews/reply`,
        dataPost
      ).then(() => {
        let config = {
          content: "You Replied Successfully",
          style: {
            background: "none",
          },
        };
        message.success(config);
      });
    } catch (error) {
      let config = {
        content: error.response.data.message ? error.response.data.message : 'Generic error occurred',
        style: {
          background: "none",
        },
        duration: 6,
      };
      message.error(config);
      console.log(error);
    }
  };

  const handleAddReview = async ({ formData, values, captchaToken }) => {
    setIsSubmitting(true);
    try {
      const data = {
        business_id: business.id,
        rating: values.rating,
        text: values.text,
      };

      await axiosWithAuth().post(
        `${process.env.REACT_APP_BE_API_URL}/api/reviews/`,
        { data, captchaToken }
      ).then((res) => {
        getAllReviewsOfBusiness();
        if (res.status === 200) {
          let config = {
            content: "Thanks For Your Review",
            style: {
              background: "none",
            },
            duration: 6,
          };
          message.success(config);
        }
      });

      setIsSubmitting(false);
      setShowModal(false);

    } catch (error) {
      let config = {
        content: error.response.data.message,
        style: {
          background: "none",
        },
        duration: 6,
      };
      message.error(config);
      console.log(error);
      setIsSubmitting(false);
    }
  };

  const handleUpdateReview = async (data, captchaToken) => {
    const dataValues = {'data': {'rating': data.values.rating, 'text': data.values.text}}
    axiosWithAuth()
      .put(
        `${process.env.REACT_APP_BE_API_URL}/api/reviews/${business.id}`,
        dataValues
      )
      .then((res) => {
        getAllReviewsOfBusiness();
        props.history.push(`/business/${business.name}/reviews`);
        let config = {
          content: "Review Updated successfully",
          style: {
            background: "none",
          },
        };
        message.success(config);
      })
      .catch((error) => {
        let config = {
          content: error.response.data.message,
          style: {
            background: "none",
          },
          duration: 6,
        };
        message.error(config);
        console.log(error.response);
      });
  };

  const handleDeleteReview = async (id) => {
    axiosWithAuth()
      .delete(
        `${process.env.REACT_APP_BE_API_URL}/api/reviews/${business.id}`
      )
      .then((res) => {
        getAllReviewsOfBusiness();
        if (res.status === 204) {
          const filteredList = review.filter((elem) => elem.id !== id);
          setReview(filteredList);
          setReviewed(false);
        }
        let config = {
          content: "Review Deleted successfully",
          style: {
            background: "none",
          },
        };
        message.success(config);
      })
      .catch((error) => {
        let config = {
          content: error.response.data.message,
          style: {
            background: "none",
          },
          duration: 6,
        };
        message.error(config);
        console.log(error.response);
      });
  };

  const deleteComment = async (id, review_id, sender_id) => {
    const APIURL = `/api/reviews/reply/${id}`;
    await axiosWithAuth().delete(APIURL)
      .then(() => {
        const newList = review.map((elem) => {
          if (elem.id === review_id) {
            elem.reviewComments = elem.reviewComments.filter(
              (comment) => comment.id !== id
            );
          }
          return elem;
        });
        setReview(newList);
        let config = {
          content: "Reply Deleted successfully",
          style: {
            background: "none",
          },
        };
        message.success(config);
      })
      .catch((error) => {
        let config = {
          content: error.response.data.message,
          style: {
            background: "none",
          },
          duration: 6,
        };
        message.error(config);
        console.log(error.response);
      });
  };

  return (
    <div id='business-reviews'>
      <div className='addReviewBtnCotainer'>
        {/* Buiness Owner Cannot Add Review of their own Business */}
        {business.owner_id === props.user.id || reviewed ? null : (
          <Tooltip
            placement='top'
            title={user && user.id ? "" : "please login for adding a review"}
            color='#fb4400'
            key='#fb4400'
          >
            <Button
              type='primary'
              onClick={() => setShowModal(true)}
              style={{
                marginBottom: "10px",
              }}
              disabled={user && user.id ? false : true}
            >
              Add Review
            </Button>
          </Tooltip>
        )}
      </div>
      {review.length > 0 &&
        review.map((data) => {
          return (
            <SingleAdvertisement
              key={data.id}
              advertisement={data}
              business={props.business}
              user={user}
              userData={data.userInfo}
              commentAdvertisement={handleReplyReview}
              commentData={data.reviewComments}
              getAllReviewsOfBusiness={getAllReviewsOfBusiness}
              deleteAdvertisement={handleDeleteReview}
              updateAdvertisement={async (values) => {
                await handleUpdateReview(values);
              }}
              deleteComment={deleteComment}
              reviewSection={true}
            />
          );
        })}

      <div className='commentsContainer'>
        <CreateAdModal
          showModal={showModal}
          setShowModal={setShowModal}
          handleSubmit={handleAddReview}
          isSubmitting={isSubmitting}
          commentModal={true}
          getAllReviewsOfBusiness={getAllReviewsOfBusiness}
          history={props.history}
        />
      </div>
    </div>
  );
}
