import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Dropdown, Menu } from "antd";
import { useEffect, useState } from "react";

export default function ChatBoxMessage({
  message,
  user,
  sender_id,
  // deleteMessage,
}) {
  const [messageClass, setMessageClass] = useState();
  // const handleAdAction = async (e) => {
  //   if (e.key == "delete-message") {
  //     await deleteMessage(message.id);
  //   }
  // };

  // const menu = (
  //   <Menu onClick={handleAdAction}>
  //     <Menu.Item key="delete-message" className="menu-text">
  //       Delete
  //     </Menu.Item>
  //   </Menu>
  // );

  useEffect(() => {
    if (user.id === sender_id) {
      setMessageClass("sender");
    } else {
      setMessageClass("recipient");
    }
  }, [sender_id, user]);

  return (
    <div>
      <div className={`message-wrapper ${messageClass}`}>
        {/* <div className="user-logo"> */}
        {/* {
            // <img src={senderLogo} alt="" width="40" height="40" />

            (message.sender_logo ? (
              <Avatar
                style={{
                  backgroundColor: "#f56a00",
                  verticalAlign: "middle",
                  width: 40,
                  height: 40,
                }}
                size="large"
                src={message.sender_logo}
              />
            ) : (
              <Avatar
                style={{
                  backgroundColor: "#f56a00",
                  verticalAlign: "middle",
                  width: 40,
                  height: 40,
                }}
                size="large"
                // gap={4}
              >
                {message.senderName.split("")[0]}
              </Avatar>
            ))} */}
        {/* </div> */}
        <div className="message-meta">
          <div
            className={!message.is_deleted ? "message-txt" : "message-deleted"}
          >
            {message}
            {/* {message.sender_id === userID.id && !message.is_deleted && (
              <div className="message-action">
                <Dropdown
                  overlay={menu}
                  trigger={["click"]}
                  placement="bottomLeft"
                  arrow
                >
                  <FontAwesomeIcon icon="ellipsis-h" />
                </Dropdown>
              </div>
            )} */}
            <div>
              {message.is_deleted
                ? "This message has been deleted."
                : message.message}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
