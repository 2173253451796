import { useNavigate, useParams } from "react-router-dom";

//** Import Modules */
import { Button } from "antd";

//** Import Components */
import BizzllLiveTv from "../../components/common/BizzllLiveTv";
import PageTitle from "../../components/header/PageTitle";
import HomepageFeaturedSlider from "../Homepage/HomepageFeaturedSlider";
import CitySidebar from "./CitySidebar";

export default function CityDeails() {
  let navigate = useNavigate();
  const params = useParams();

  const handleCityEvents = () => {
    navigate(`/events?location=${params.city}`);
  };

  const handleSupportBusiness = () => {
    navigate(`/directory?location=${params.city}&searchDataPage=${0}`);
  };

  return (
    <div id="bizzll-page">
      <PageTitle pageTitle={`${params.city}`} />

      <div id="internal-city">
        <div className="inner-container">
          <div className="internal-sidebar">
            <CitySidebar />
          </div>

          <div className="cityListItem-item">
            <div className="mainCity-img">
              <iframe
                width="100%"
                height="500"
                src="https://www.youtube.com/embed/Rtk8-rE3UqI?rel=0"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                title="Welcome To BIZZLL"
              ></iframe>
            </div>

            <div className="city-btns">
              <div className="btn-wrapper">
                <Button type="primary" size="large" onClick={handleCityEvents}>
                  View {params.city} Events
                </Button>
              </div>

              <div className="btn-wrapper">
                <Button
                  type="primary"
                  size="large"
                  onClick={handleSupportBusiness}
                >
                  Support {params.city} Businesses
                </Button>
              </div>
            </div>
          </div>

          <div className="internal-sidebar">
            <CitySidebar />
          </div>
        </div>

        <HomepageFeaturedSlider />
      </div>
    </div>
  );
}
