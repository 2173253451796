//** Import Modules */
import { call, put } from "redux-saga/effects";
import { setCookie } from "../../../common/manageCookies";
import { setUserGeneralData } from "../../reducers/user";
import {
  requestGetReferralInfo,
  requestGetUser,
  requestGetUserRole,
} from "../requests/user";

export function* handleUserGeneralData() {
  try {
    const responseGetUser = yield call(requestGetUser);
    const responseGetUserRole = yield call(requestGetUserRole);

    // We check if we have a referral code in place
    let referrerData = {};
    const referralCode = responseGetUser.data.meta_data.filter(
      (item) => item.meta_key === "referrer"
    )[0];

    if (referralCode) {
      const responseGetReferrer = yield call(
        requestGetReferralInfo,
        referralCode.meta_value
      );

      referrerData = {
        user_id: responseGetReferrer.data.user_id,
        type: responseGetReferrer.data.role_name,
      };
    }

    const user = {
      fname: responseGetUser.data.user_fname,
      id: responseGetUser.data.id,
      lname: responseGetUser.data.user_lname,
      email: responseGetUser.data.user_email,
      role: responseGetUserRole.data,
      meta_data: responseGetUser.data.meta_data,
      referral: referrerData,
    };

    yield put(setUserGeneralData(user));
  } catch (err) {
    console.log(err.response);

    if (err.response.status === 403) {
      setCookie("ubt", "", 0);
      window.location.replace(process.env.REACT_APP_DASHBOARD_URL);
    }
  }
}
