import { Modal } from "antd";
import Comment from "./Comment";

const CommentModal = (props) => {
  const {
    showModal,
    setShowModal,
    commentData,
    isToday,
    user,
    advertisement,
    deleteComment,
    userBlocked,
    group,
  } = props;
  return (
    <div>
      <Modal
        title={`Total ${commentData.length} comments`}
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={[]}
      >
        <div className="recent-comments comment-modal">
          {commentData.length
            ? commentData.map((comment) => (
                <Comment
                  comment={comment}
                  user={user}
                  isToday={isToday}
                  advertisement={advertisement}
                  deleteComment={() =>
                    deleteComment(
                      comment.id,
                      comment.activity_wall_id,
                      comment.sender_id
                    )
                  }
                  userBlocked={userBlocked}
                  group={group}
                />
              ))
            : "There is no comment on this advertisement"}
        </div>
      </Modal>
    </div>
  );
};

export default CommentModal;
