import GroupChat from "./GroupChat";

export default function GroupMainPage(props) {
  return (
    <div>
      <GroupChat
        group={props.group}
        user={props.user}
        propsData={props.propsData}
        userBlocked={props.userBlocked}
      />
    </div>
  );
}
