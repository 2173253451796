//** Import Modules */
import React from "react";
import {
  faMagnifyingGlass,
  faCalendarDays,
  faUserGroup,
  faCity,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function MobileFooterBar() {
  return (
    <div id='mobile-footer-bar'>
      <div className='inner-component'>
        <a href='/directory'>
          <span>
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </span>
          Directory
        </a>
        <a href='/events'>
          <span>
            <FontAwesomeIcon icon={faCalendarDays} />
          </span>
          Events
        </a>
        <a href='/bizzll-city'>
          <span>
            <FontAwesomeIcon icon={faCity} />
          </span>
          Cities
        </a>
        <a href='/groups'>
          <span>
            <FontAwesomeIcon icon={faUserGroup} />
          </span>
          Groups
        </a>
      </div>
    </div>
  );
}
