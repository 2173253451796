//** Import Modules */
import { Helmet } from "react-helmet";
import PageTitle from "../../components/header/PageTitle";

export default function NotFound() {
  return (
    <div>
      <Helmet>
        <title>Page Not Found</title>
        <meta name='description' content='Page Not Found' />
      </Helmet>
      <PageTitle pageTitle='404 - Page Not Found' />
      Seems like the page you are looking for is not found
    </div>
  );
}
