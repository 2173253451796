import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Menu } from "antd";
import senderLogo from "./../../assets/images/bizzll-icon.png";

export default function ChatBoxMessage({
  message,
  userID,
  sameSender,
  deleteMessage,
}) {
  const [messageClass, setMessageClass] = useState();

  const handleAdAction = async (e) => {
    if (e.key == "delete-message") {
      await deleteMessage(message.id);
    }
  };

  const menu = (
    <Menu onClick={handleAdAction}>
      <Menu.Item key="delete-message" className="menu-text">
        Delete
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (message.sender_id === userID) {
      setMessageClass("sender");
    } else {
      setMessageClass("recipient");
    }
  }, [message.sender_id, userID]);

  return (
    <div>
      <div className={`message-wrapper ${messageClass}`}>
        <div className="user-logo">
          {!sameSender && (
            <img src={senderLogo} alt="" width="40" height="40" />
          )}
        </div>
        <div className="message-meta">
          <div
            className={!message.is_deleted ? "message-txt" : "message-deleted"}
          >
            {message.sender_id === userID && !message.is_deleted && (
              <div className="message-action">
                <Dropdown
                  overlay={menu}
                  trigger={["click"]}
                  placement="bottomLeft"
                  arrow
                >
                  <FontAwesomeIcon icon="ellipsis-h" />
                </Dropdown>
              </div>
            )}
            <div>
              {message.is_deleted
                ? "This message has been deleted."
                : message.message}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
