import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCookie } from "../../../common/manageCookies";

const CommentAdForm = (props) => {
  const { commentAdvertisement, getAllReviewsOfBusiness } = props;
  const [form] = Form.useForm();
  // State to determine when a user is logged in
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Logic for checking if have an auth token
    const getToken = getCookie("ubt");

    if (getToken) {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <div className="add-comment">
      <Form
        form={form}
        layout="vertical"
        onFinish={async (values) => {
          await commentAdvertisement(values);
          getAllReviewsOfBusiness()
          form.resetFields();
        }}
        onFinishFailed={() => console.log("failed")}
        autoComplete="off"
      >
        <Form.Item
          name="text"
          rules={[
            {
              required: true,
              message: "Please enter text for comment",
            },
          ]}
        >
          <Input
            className="post-form-input"
            id="comment-input"
            suffix={
              <FontAwesomeIcon
                icon="arrow-right"
                htmlType="submit"
                onClick={() => form.submit()}
              />
            }
            disabled={!isLoggedIn}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default CommentAdForm;
