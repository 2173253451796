import { Button } from "antd";
import React from "react";
import { FE_URL } from "../../../common/constants";

export default function AboutOrganizer({ eventData }) {
  const businessData = eventData.businessData;
  const businessURL = `${FE_URL}/business/${businessData.meta_data.business_handle}`;

  return (
    <div className="about-organizer">
      <div className="organizer-wrapper">
        <a href={businessURL} className="logo">
          <img
            src={businessData.meta_data.business_logo}
            alt={businessData.name}
          />
        </a>

        <div className="organizer-info">
          <h3>About Organizer</h3>
          <h4>{businessData.name}</h4>

          <p>
            {businessData.meta_data.business_description &&
              businessData.meta_data.business_description.substring(0, 200)}
            ...
          </p>

          <div className="bottom-btns">
            <Button type="primary" href={businessURL}>
              Visit Business Page
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
