import React from "react";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import { FE_URL } from "../../../common/constants";

export default function ShareBar({ eventData }) {
  const iconSize = 50;
  const shareURL = `${FE_URL}/events/${eventData.id}`;
  const shareTitle = `${eventData.name} - BIZZLL Event`;
  const hashtags = ["BIZZLL", "BIZZLLEvents"];

  return (
    <div className='share-bar'>
      <div className='social-media'>
        <div className='label'>Share This Event:</div>
        <FacebookShareButton
          url={shareURL}
          title={shareTitle}
          children={<FacebookIcon size={iconSize} />}
        />

        <TwitterShareButton
          url={shareURL}
          title={shareTitle}
          hashtags={hashtags}
          children={<TwitterIcon size={iconSize} />}
          via='Bizzlllocal'
        />

        <LinkedinShareButton
          url={shareURL}
          title={shareTitle}
          source='BIZZLL App'
          children={<LinkedinIcon size={iconSize} />}
        />

        <EmailShareButton
          url={shareURL}
          subject={shareTitle}
          children={<EmailIcon size={iconSize} />}
        />
      </div>
    </div>
  );
}
