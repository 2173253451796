//** Import Modules */
import {
  faEnvelope,
  faMapMarkerAlt,
  faFlag,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Skeleton, Tooltip, Image } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReportModal from "../../../components/reportModal/index";

//** Import Assets */
import defaultLogo from "../../../assets/images/bizzll-icon.png";
import defaultCover from "../../../assets/images/geopattern.png";
import axiosGeneral from "../../../utils/axiosGeneral";
import FollowBusiness from "./FollowBusiness";
export default function ProfileHeader(props) {
  //* Bring the Props
  const { user, business, loading, baseURL, updateFollowerCount, openChatbox } =
    props;

  //* Get the data to use for the profile
  const initialListingData = {
    logo: defaultLogo,
    coverImg: defaultCover,
    name: (
      <Skeleton.Input style={{ width: 200 }} active={true} size='default' />
    ),
    userName: <Skeleton.Avatar active={true} size='default' shape='circle' />,
    category: "",
    city: "",
    state: "",
    zip: "",
  };

  const [listingData, setListingData] = useState(initialListingData);
  let [review, setReview] = useState([]);
  const [avgReview, setAvgReview] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    if (!loading) {
      const prepareListingData = {
        ...listingData,
        name: business.name,
        userName: business.meta_data.business_handle,
        category: business.meta_data.business_category,
        city: business.meta_data.business_city,
        state: business.meta_data.business_state,
        zip: business.meta_data.business_zip,
        logo: business.meta_data.business_logo || defaultLogo,
        coverImg: business.meta_data.business_cover || defaultCover,
      };

      setListingData(prepareListingData);
      getAllReviewsOfBusiness();
    }
  }, [business, loading]);

  useEffect(() => {
    if (review.length > 0) {
      let averageRating = 0;
      review.forEach((data) => {
        averageRating = averageRating + data.rating;
      });
      let finalAvgRating = averageRating / review.length;
      setAvgReview(finalAvgRating);
    }
  }, [review, avgReview]);

  const getAllReviewsOfBusiness = async () => {
    const response = await axiosGeneral().get(
      `${process.env.REACT_APP_BE_API_URL}/api/reviews/getAllReviews/${props.business.id}`
    );
    if (response.status === 200) {
      setReview(response.data);
    }
  };

  // Build Profile URL
  const businessURL = `${baseURL}/${listingData.userName}`;

  return (
    <header id='bizzll-profile-header' className='bizzll-profile-header'>
      <div
        className={
          listingData.coverImg === defaultCover
            ? "profile-header-cover no-cover"
            : "profile-header-cover"
        }
      >
        <img alt="Profile Cover" className="header-cover" src={listingData.coverImg}></img>

      </div>

      <div className='inner-container'>
        <div className='profile-header-content'>
          <div className='profile-header-image'>
            <Image width='auto' src={listingData.logo} alt={`Logo for ${listingData.name}`} />
          </div>

          <div className='profile-meta'>
            <div className='business-name'>
              <h2>
                {listingData.name} <span>(@{listingData.userName})</span>
              </h2>
            </div>
            <div className='address'>
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              <span>
                {listingData.city}, {listingData.state} {listingData.zip} - USA
              </span>
            </div>
          </div>

          <div className='networking'>
            {business.owner_id === user.id ? (
              <div className='message-btn'>
                <Button
                  title={`Edit Profile`}
                  onClick={() => {
                    navigate(
                      `/member-dashboard/business-settings/${business.id}`
                    );
                  }}
                  type='primary'
                >
                  Edit Profile
                </Button>
              </div>
            ) : (
              <>
                <div className='message-btn'>
                  <Tooltip
                    placement='top'
                    title={
                      user && user.id
                        ? ""
                        : "please login for message to business owner"
                    }
                    color='#fb4400'
                    key='#fb4400'
                  >
                    <Button
                      onClick={() => {
                        openChatbox();
                      }}
                      type='primary'
                      title={`Message ${listingData.name}`}
                      disabled={user && user.id ? false : true}
                    >
                      <FontAwesomeIcon icon={faEnvelope} />
                    </Button>
                  </Tooltip>
                </div>

                <FollowBusiness
                  user={user}
                  business={business}
                  updateFollowerCount={updateFollowerCount}
                />

                <div className='reportTextContainer'>
                  <Tooltip
                    placement='top'
                    title={
                      user && user.id
                        ? ""
                        : "please login for report to business"
                    }
                    color='#fb4400'
                    key='#fb4400'
                  >
                    <Button
                      onClick={() => setIsModalVisible(true)}
                      disabled={user && user.id ? false : true}
                      type='primary'
                    >
                      <FontAwesomeIcon
                        icon={faFlag}
                        style={{ marginRight: 10 }}
                      />{" "}
                      Report
                    </Button>
                  </Tooltip>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <ReportModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        accusedId={business.owner_id}
        user={user}
        adId={business.id}
        type={"Business"}
        option={[
          { key: "Fake Business", value: "Fake Business" },
          { key: "Inappropriate Content", value: "Inappropriate Content" },
          {
            key: "Impersonating other Business",
            value: "Impersonating other Business",
          },
        ]}
      />
    </header>
  );
}
