import { Avatar, Modal } from "antd";

const LikeModal = (props) => {
  const { showModal, setShowModal, shareData } = props;
  return (
    <div>
      <Modal
        title={`Total ${shareData.length} shares`}
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={[]}
      >
        <div className="likes-container">
          {shareData.length
            ? shareData.map((share) => (
                <div className="likes-list">
                  <div>
                    <Avatar size={35}>{`${share.userInfo.user_fname
                      .substring(0, 1)
                      .toUpperCase()} ${share.userInfo.user_lname
                      .substring(0, 1)
                      .toUpperCase()}`}</Avatar>
                  </div>
                  <div className="like-username">
                    {`${share.userInfo.user_fname
                      .substring(0, 1)
                      .toUpperCase()}${share.userInfo.user_fname.substring(
                      1,
                      share.userInfo.user_fname.length
                    )} ${share.userInfo.user_lname
                      .substring(0, 1)
                      .toUpperCase()}${share.userInfo.user_lname.substring(
                      1,
                      share.userInfo.user_lname.length
                    )}`}
                  </div>
                </div>
              ))
            : "There is no share on this advertisement"}
        </div>
      </Modal>
    </div>
  );
};

export default LikeModal;
