import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar, Dropdown, Menu
} from "antd";
import moment from "moment";
import { useState } from "react";
import ReportModal from '../../../components/reportModal/index';
import DeleteModal from "./DeleteComment";
import { DeleteOutlined } from '@ant-design/icons';

const Comment = (props) => {
  const {
    comment,
    user,
    isToday,
    deleteComment,
    business,
    entity,
    advertisement,
  } = props;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const menu = (
    <Menu>
      <Menu.Item key="reportComment" onClick={() => {
        console.log("Comment", comment);
        setIsModalVisible(true)}}>
        Report
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="comment-header">
      <ReportModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        accusedId={comment.sender_id}
        user={user}
        adId={comment.id}
        type={"Comment"}
        option={[
          { key: "Inappropriate behavior", value: "Inappropriate behavior" },
          { key: "Nudity", value: "Nudity" },
          {
            key: "Violence",
            value: "Violence",
          },
          {
            key: "Harassment",
            value: "Harassment",
          },
        ]}
      />

      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        deleteComment={() => deleteComment()}
      />
      <div>
        {comment.userInfo ? (
          <Avatar>
            {`${comment.userInfo.user_fname
              .substring(0, 1)
              .toUpperCase()} ${comment.userInfo.user_lname
              .substring(0, 1)
              .toUpperCase()}`}
          </Avatar>
        ) : (
          <Avatar>
            {`${
              user.user_fname && user.user_fname.substring(0, 1).toUpperCase()
            } ${
              user.user_lname && user.user_lname.substring(0, 1).toUpperCase()
            }`}
          </Avatar>
        )}
      </div>
      {comment.userInfo ? (
        <>
          <div className="post-header-name comment">
            <div className="post-owner-name">
              {`${comment.userInfo.user_fname
                .substring(0, 1)
                .toUpperCase()}${comment.userInfo.user_fname.substring(
                1,
                comment.userInfo.user_fname.length
              )} ${comment.userInfo.user_lname
                .substring(0, 1)
                .toUpperCase()}${comment.userInfo.user_lname.substring(
                1,
                comment.userInfo.user_lname.length
              )}`}
              <span>
                {isToday(comment.created)
                  ? moment.utc(comment.created).local().format("LT")
                  : moment.utc(comment.created).local().format("L")}
              </span>
              <span className="commentReportContainer">
                <Dropdown
                  overlay={menu}
                  trigger={["click"]}
                  placement="bottomLeft"
                  arrow
                >
                  <FontAwesomeIcon icon="ellipsis-h" />
                </Dropdown>
              </span>
            </div>
            <div className="post-time">{comment.text}</div>
          </div>
        </>
      ) : (
        <>
          <div className="post-header-name comment">
            <div className="post-owner-name">
              {`${
                user &&
                user.user_fname &&
                user.user_fname.substring(0, 1).toUpperCase()
              }${
                user &&
                user.user_fname &&
                user.user_fname.substring(
                  1,
                  user && user.user_fname && user.user_fname.length
                )
              } ${
                user &&
                user.user_lname &&
                user.user_lname.substring(0, 1).toUpperCase()
              }${
                user &&
                user.user_lname &&
                user.user_lname.substring(
                  1,
                  user && user.user_lname && user.user_lname.length
                )
              }`}
              <span>
                {isToday(comment.created)
                  ? moment.utc(comment.created).local().format("LT")
                  : moment.utc(comment.created).local().format("L")}
              </span>
              <span className="commentReportContainer">
                <Dropdown
                  overlay={menu}
                  trigger={["click"]}
                  placement="bottomLeft"
                  arrow
                >
                  <FontAwesomeIcon icon="ellipsis-h" />
                </Dropdown>
              </span>
            </div>
            <div className="post-time">{comment.text}</div>
          </div>
        </>
      )}
      {/*(entity === "business"
        ? business.owner_id === user.id
        : advertisement.user_id === user.id)*/
        (comment.sender_id === user.id) && (
        <div className="comment-delete">
          <FontAwesomeIcon
            icon="trash-alt"
            onClick={() => setShowDeleteModal(true)}
          />
        </div>
      )}
    </div>
  );
};

export default Comment;
