import { useState } from "react";
import { FE_URL } from "../common/constants";
import axiosWithAuth from "../utils/axiosWithAuth";

export const useCreateOrder = (user, cartItems, subTotal, amountTotal) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const createOrder = () => {
    setIsSubmitting(true);

    const APIURL = "/api/orders/create";

    let payload = {
      order: {
        user_id: user.id,
        business_id: cartItems[0].businessID,
        status: "Completed",
        subtotal: subTotal,
        total: amountTotal,
      },
      orderItems: cartItems.map((item) => {
        return {
          type: item.itemType,
          item_id: item.itemID,
          quantity: item.itemQuantity,
        };
      }),
      user: {
        fname: user.fname,
        lname: user.lname,
        email: user.email,
      },
    };

    if (cartItems[0].itemType === "event_ticket") {
      payload = {
        ...payload,
        attendee: {
          user_id: user.id,
          event_id: cartItems[0].sourceID,
          ticket_id: cartItems[0].itemID,
          quantity: 1,
          price: cartItems[0].itemPrice,
        },
      };
    }

    if (cartItems[0].itemType === "service_product") {
      payload = {
        ...payload,
        appointment: {
          buyer_id: user.id,
          product_id: cartItems[0].itemID,
          status: "confirmed",
          start_time: cartItems[0].startTime,
          business_id: cartItems[0].businessID,
        },
      };
    }

    axiosWithAuth()
      .post(APIURL, payload)
      .then((res) => {
        window.location.replace(
          `${FE_URL}/order-confirmation/${res.data.orderID}`
        );
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  // Return all necessary functions/states
  return [createOrder, isSubmitting];
};
