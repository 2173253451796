import React, { useState } from "react";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, Modal, Select, Alert } from "antd";
import { FE_URL } from "../../../common/constants";
import { useSelector } from "react-redux";

export default function RegisterAttendee({ eventData }) {
  const [isSubmitting, setisSubmitting] = useState(false);

  //* Tickets data
  const tickets = eventData.tickets;
  const [currTicket, setCurrTicket] = useState({});

  const [form] = Form.useForm();

  //* Handle Form Functions
  const [ticketModal, setTickeModal] = useState(false);

  const user = useSelector((state) => state.user.user);

  const closeTicketModal = () => {
    setTickeModal(false);
  };

  const handleTicketChange = (value) => {
    // Get the ticket data
    const ticketData = tickets.filter((item) => item.id === value)[0];

    setCurrTicket(ticketData);
  };

  //*Add ticket to cart
  const handleTicketBuy = () => {
    setisSubmitting(true);

    // Prepare the info to insert to checkout
    const ticketData = currTicket;
    const businessStripeID = eventData.businessData.meta_data.stripe_account_id;

    const ticketItemInfo = [
      {
        itemID: ticketData.id,
        itemName: `${eventData.name} - ${ticketData.name}`,
        itemPrice: ticketData.price,
        sourceID: eventData.id,
        itemQuantity: 1,
        businessID: eventData.business_id,
        itemTotal: parseFloat(ticketData.price) * 1,
        businessStripeID,
        itemType: "event_ticket",
      },
    ];

    // Check to ensure that there are legitimate tickets being called
    if (eventData && tickets && tickets.length > 0) {
      // First, remove any item from localstorage
      localStorage.removeItem("cart");

      // Then, add the new item in the local storage
      window.localStorage.setItem("cart", JSON.stringify(ticketItemInfo));

      // redirect to checkout
      window.location.replace(`${FE_URL}/checkout`);
    }
  };

  return (
    <>
      {
        user.id != undefined && 
          <div>
            <Button
              onClick={() => setTickeModal(true)}
              type='primary'
              icon={<FontAwesomeIcon icon={faEnvelope} />}
            >
              Get Ticket
            </Button>

            <Modal
              title='Get Ticket'
              visible={ticketModal}
              onCancel={closeTicketModal}
              footer={null}
            >
              <Form name='control-ref' onFinish={handleTicketBuy} form={form}>
                <Form.Item
                  name='ticket_type'
                  label='Ticket Type'
                  rules={[
                    {
                      required: true,
                      message: "Ticket Type is Required!",
                    },
                  ]}
                >
                  <Select
                    placeholder='Select ticket type'
                    onChange={handleTicketChange}
                    options={
                      tickets.length > 0 &&
                      tickets.map((ticket) => {
                        return {
                          key: ticket.id,
                          value: ticket.id,
                          label: `${ticket.name} ($${ticket.price})`,
                        };
                      })
                    }
                  />
                </Form.Item>

                {currTicket.description && currTicket.description !== "" && (
                  <p>{currTicket.description}</p>
                )}

                <Form.Item>
                  <Button type='primary' htmlType='submit' loading={isSubmitting}>
                    Buy
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
          </div>
      }

      {
        user.id == undefined && 
          <div>
            <Button
              onClick={() => setTickeModal(true)}
              type='primary'
              icon={<FontAwesomeIcon icon={faEnvelope} />}
            >
              Get Ticket
            </Button>
            <Modal
              title='Get Ticket'
              visible={ticketModal}
              onCancel={closeTicketModal}
              footer={null}
            >
              <div className="warning-boxes">
                <Alert
                  message="You need to be logged in to take a ticket"
                  description={
                    <>
                      <h4>Please login/register <a href={'http://localhost:3005/?redirect=http://localhost:3000/events/' + eventData.id}>HERE</a></h4>
                      <h4>Thank you!</h4>
                    </>
                  }
                  type="warning"
                  showIcon
                />
              </div>
            </Modal>
          </div>
      }
    </>
  );
}
