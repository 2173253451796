//** Import Modules */
import axiosGeneral from "../../../utils/axiosGeneral";
import axiosWithAuth from "../../../utils/axiosWithAuth";

export function requestGetEvent(eventID) {
  return axiosGeneral().request({
    method: "get",
    url: `${process.env.REACT_APP_BE_API_URL}/api/event/${eventID}`,
  });
}

export function requestGetEventBusiness(businessID) {
  return axiosGeneral().request({
    method: "get",
    url: `${process.env.REACT_APP_BE_API_URL}/api/business/${businessID}`,
  });
}

export function requestGetEventsTickets(eventID) {
  return axiosWithAuth().request({
    method: "get",
    url: `${process.env.REACT_APP_BE_API_URL}/api/event-ticket/getEventTickets/${eventID}`,
  });
}
