import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Empty, Skeleton } from "antd";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";

import { SocketContext } from "../../contexts/SocketIO";
import axiosWithAuth from "../../utils/axiosWithAuth";
import CreatePostModal from "./CreatePostModal";
import SingleAdvertisement from "./SingleAdvertisement";

const GroupChat = (props) => {
  const { user, group, userBlocked } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [advertisementList, setAdvertisementList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [group, setGroup] = useState({});
  const [isUserJoined, setIsUserJoined] = useState(false);

  const socket = useContext(SocketContext);
  const routeParams = useParams();

  const createAdvertisement = async ({ formData, values }) => {
    setIsSubmitting(true);

    const APIURL = `/api/group-wall/create`;
    try {
      const res = await axiosWithAuth().post(APIURL, {
        text: values.text,
        group_id: group.id,
        user_id: user.id,
      });
      const token = localStorage.getItem("token");

      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(token).token}`,
          "content-type": `multipart/form-data`,
        },
      };
      if (values.attachments) {
        const imageRes = await axios.post(
          `${process.env.REACT_APP_BE_API_URL}/api/group-wall/uploadImage/groups-${group.id}-${res.data.id}`,
          formData,
          config
        );

        await axiosWithAuth().put(`/api/group-wall/update/${res.data.id}`, {
          data: { attachments: imageRes.data.url, firstUpdate: true },
        });

        res.data.attachments = imageRes.data.url;
      }
      setIsSubmitting(false);
      setShowModal(false);
      if (res.status == 201) {
        setAdvertisementList([res.data, ...advertisementList]);
      }
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
    }
  };

  const deleteAdvertisement = async (id) => {
    const APIURL = `/api/group-wall/delete/${id}`;
    try {
      const res = await axiosWithAuth().delete(APIURL);
      if (res.status == 200) {
        const filteredList = advertisementList.filter((ad) => ad.id != id);
        setAdvertisementList(filteredList);
      }
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
    }
  };

  const updateAdvertisement = async (id, data) => {
    const APIURL = `/api/group-wall/update/${id}`;
    try {
      if (data.values.attachments && data.values.attachments.preview) {
        const token = localStorage.getItem("token");

        const config = {
          headers: {
            Authorization: `Bearer ${JSON.parse(token).token}`,
            "content-type": `multipart/form-data`,
          },
        };
        const imageUrl = await axios.post(
          `${process.env.REACT_APP_BE_API_URL}/api/group-wall/uploadImage/groups-${group.id}-${id}`,
          data.formData,
          config
        );
        data.values.attachments = imageUrl.data.url;
        data.values.shouldDeleteImage = true;
      }
      const res = await axiosWithAuth().put(APIURL, { data: data.values });
      if (res.status == 202) {
        const newAdvertisementList = advertisementList.map((ad) => {
          if (ad.id == id) {
            return res.data.data;
          }
          return ad;
        });
        setAdvertisementList(newAdvertisementList);
      }
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
    }
  };

  const likeAdvertisement = async (id) => {
    if (!isUserJoined) {
      return;
    }
    const APIURL = `/api/group-wall/likeAdvertisement`;
    const res = await axiosWithAuth().post(APIURL, {
      advertisementId: id,
      groupId: group.id,
    });
    if (res.status == 201) {
      const newAdvertisementList = advertisementList.map((ad) => {
        if (ad.id == id) {
          return res.data.data;
        }
        return ad;
      });
      setAdvertisementList(newAdvertisementList);
      //   if (business.owner_id != user.id && res.data.shouldNotify) {
      //     socket.emit("send-notification", business, "like");
      //   }
    }
  };

  const unlikeAdvertisement = async (id) => {
    if (!isUserJoined) {
      return;
    }
    const APIURL = `/api/group-wall/unlikeAdvertisement`;
    const res = await axiosWithAuth().post(APIURL, {
      advertisementId: id,
    });
    if (res.status == 201) {
      const newAdvertisementList = advertisementList.map((ad) => {
        if (ad.id == id) {
          return res.data;
        }
        return ad;
      });
      setAdvertisementList(newAdvertisementList);
    }
  };

  const commentAdvertisement = async (id, data) => {
    if (!isUserJoined) {
      return;
    }
    const APIURL = `/api/group-wall/commentAdvertisement`;
    const res = await axiosWithAuth().post(APIURL, {
      advertisementId: id,
      text: data.text,
      groupId: group.id,
    });
    if (res.status == 201) {
      const newAdvertisementList = advertisementList.map((ad) => {
        if (ad.id == id) {
          return res.data.data;
        }
        return ad;
      });
      setAdvertisementList(newAdvertisementList);
      //   if (business.owner_id != user.id) {
      //     socket.emit("send-notification", business, "comment");
      //   }
      // setCommented(!commented);
    }
  };

  const shareAdvertisement = async (id, text) => {
    if (!isUserJoined) {
      return;
    }
    const APIURL = `/api/group-wall/shareAdvertisement`;
    const res = await axiosWithAuth().post(APIURL, {
      advertisementId: id,
      groupId: group.id,
      text,
    });
    if (res.status == 200) {
      const newAdvertisementList = advertisementList.map((ad) => {
        if (ad.id == id) {
          return res.data.data;
        }
        return ad;
      });
      setAdvertisementList(newAdvertisementList);
      // if (business.owner_id != user.id) {
      //   socket.emit("send-notification", business, "comment");
      // }
    }
  };

  const deleteComment = async (id, activity_wall_id, sender_id) => {
    if (!isUserJoined) {
      return;
    }
    const APIURL = `/api/group-wall/deleteComment`;
    const res = await axiosWithAuth().post(APIURL, {
      id,
      activity_wall_id,
      sender_id,
    });
    if (res.status == 202) {
      const newList = advertisementList.map((ad) => {
        if (ad.id == activity_wall_id) {
          ad.commentData = ad.commentData.filter((comment) => comment.id != id);
        }
        return ad;
      });
      setAdvertisementList(newList);
    }
  };

  const getGroupPost = async () => {
    try {
      if (group.id) {
        const APIURL = `/api/group-wall/getAllAdvertisement/${group.id}`;
        const res = await axiosWithAuth().get(APIURL);
        if (res.status == 200) {
          const sortedAdvertisements = res.data.sort(function (a, b) {
            return new Date(b.created) - new Date(a.created);
          });
          setAdvertisementList(sortedAdvertisements);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  // const getGroup=async ()=>{
  //   if(id){
  //     const GET_GROUP_INFO = `/api/group/getGroupById/${id}`;
  //     const res = await axiosWithAuth().get(GET_GROUP_INFO);
  //     if (res.status == 200) {
  //       setGroup(res.data);
  //       if (
  //         res.data.usersData &&
  //         res.data.usersData.find(
  //           (elem) => elem.user_id == user.id && !elem.is_blocked
  //         )
  //       ) {
  //         setIsUserJoined(true);
  //       }
  //     }
  //   }

  // }

  useEffect(async () => {
    //  await getGroup();
    if (group.id) {
      if (
        group.usersData &&
        group.usersData.find(
          (elem) => elem.user_id == user.id && !elem.is_blocked
        )
      ) {
        setIsUserJoined(true);
      }
    }
    await getGroupPost();
  }, [group]);

  return (
    <div>
      <h3 className="page-heading">
        <span>
          <FontAwesomeIcon icon="ad" />
        </span>
        Posts
      </h3>
      <div className="dashboard-page-content">
        {isUserJoined && (
          <div>
            <Button
              className="add-post-btn btn-right"
              onClick={() => setShowModal(true)}
              style={{ marginRight: "20px" }}
            >
              + Post
            </Button>
          </div>
        )}

        <CreatePostModal
          showModal={showModal}
          setShowModal={setShowModal}
          handleSubmit={createAdvertisement}
          isSubmitting={isSubmitting}
        />
        {isLoading ? (
          <Skeleton active className="pre-content" />
        ) : advertisementList.length > 0 ? (
          <div className="all-advertisements">
            {advertisementList.map((advertisement) => (
              <SingleAdvertisement
                key={advertisement.id}
                advertisement={advertisement}
                group={group}
                user={user}
                deleteAdvertisement={deleteAdvertisement}
                updateAdvertisement={async (values) =>
                  await updateAdvertisement(advertisement.id, values)
                }
                isSubmitting={isSubmitting}
                likeAdvertisement={likeAdvertisement}
                unlikeAdvertisement={unlikeAdvertisement}
                commentAdvertisement={commentAdvertisement}
                liked={advertisement.likeData.find(
                  (elem) => elem.sender_id == user.id
                )}
                commentData={advertisement.commentData.sort(function (a, b) {
                  return new Date(b.created) - new Date(a.created);
                })}
                shareAdvertisement={shareAdvertisement}
                deleteComment={deleteComment}
                userBlocked={userBlocked}
              />
            ))}
          </div>
        ) : (
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 60,
            }}
            description={<span>This group doesn't have any posts.</span>}
            className="pre-content"
          ></Empty>
        )}
      </div>
    </div>
  );
};

export default GroupChat;
