import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import LoadingContent from "../../common/LoadingContent";
import PageTitleNoAdd from "../../components/header/PageTitleNoAdd";
import axiosWithAuth from "../../utils/axiosWithAuth";

export default function OrderConfirmationPage() {
  const { id } = useParams();

  const [orderData, setOrderData] = useState({});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  console.log("orderData", orderData);

  useEffect(() => {
    const APIURL = `/api/orders/${id}`;

    axiosWithAuth()
      .get(APIURL)
      .then((res) => {
        setOrderData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response);
        setError(err);
      });
  }, [id]);

  return (
    <div id='order-confirmation'>
      <Helmet>
        <title>BIZZLL - Order Confirmation</title>
      </Helmet>

      <PageTitleNoAdd pageTitle='Order Confirmation' />

      <div className='page-wrapper'>
        {loading ? (
          <LoadingContent />
        ) : (
          <div className='inner-container'>
            <div className='header'>
              <h2>Thank you for your order!</h2>

              <h3>Order Number: {orderData.id}</h3>
            </div>

            <div className='order-details'>
              <h3>Order Details</h3>

              <div className='products-table'>
                <div className='row labels'>
                  <div className='item'>Item</div>
                  <div className='price'>Price</div>
                  <div className='quantity'>Quantity</div>
                  <div className='total'>Total</div>
                </div>

                {orderData.orderItems.map((item) => {
                  let productName;
                  let productDescription;
                  const fullPrice = item.price * item.quantity;

                  if (item.type === "event_ticket") {
                    productName = item.ticket.event_name;
                    productDescription = item.ticket.ticket_name;
                  } else if (item.type === "service_product") {
                    productName = item.appointment.name;
                    productDescription = "Reserved a time block of 00:30";
                  }

                  return (
                    <div className='row items' key={item.id}>
                      <div className='item'>
                        {productName}
                        <span>{productDescription}</span>
                      </div>
                      <div className='price'>${item.price.toFixed(2)}</div>
                      <div className='quantity'>{item.quantity}</div>
                      <div className='total'>${fullPrice.toFixed(2)}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}

        {error !== "" && (
          <div className='inner-container'>
            <div className='header'>
              <h2>There was an error retrieving order</h2>

              <h3>{error}</h3>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
