//** Import Modules */
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

//** Import Components */
import PageTitle from "../../components/header/PageTitle";
import axiosGeneral from "../../utils/axiosGeneral";
import EventListing from "./EventListing";
import EventSidebar from "./EventSidebar";

export default function Event() {
  const [cities, setCities] = useState([]);
  const [updateModalData, setUpdateModalData] = useState([]);

  useEffect(() => {
    getAllCities();
  }, []);

  const getAllCities = async () => {
    const response = await axiosGeneral().get(
      `${process.env.REACT_APP_BE_API_URL}/api/city/getAllCities`
    );
    if (response && response.status === 200) {
      setCities(response.data);
    }
  };

  const URLQueryVars = new URL(document.location).searchParams;
  const location = URLQueryVars.get("location");

  return (
    <div id="bizzll-page">
      <Helmet>
        <title>Bizzll Events</title>
        <meta name="description" content="This is the Events of Bizzll" />
      </Helmet>

      <PageTitle
        pageTitle={<>{location ? `Events - ${location}` : "Events"}</>}
      />

      <div id="bizzll-events" className="page-with-sidebar">
        <div className="inner-container">
          <aside className="page-sidebar">
            <EventSidebar cities={cities} />
          </aside>

          <div className="page-content">
            <EventListing
              setUpdateModalData={setUpdateModalData}
              updateModalData={updateModalData}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
