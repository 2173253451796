//** Import Modules */
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Carousel, Col, Row } from "antd";

//** Import Assets */
import DirectorySlide from "../../assets/images/directory-slide.jpg";
import NetworkingSlide from "../../assets/images/networking-slide.jpg";

export default function HomepageFeaturedSlider() {
  function onChange(a, b, c) {}

  const slides = [
    {
      slideTitle: "JOIN BIZZLL'S BUSINESS DIRECTORY",
      slideDesc: (
        <>
          WITH <strong>BIZZLL’S BUSINESS DIRECTORY</strong> YOU{" "}
          <strong>IMPROVE</strong> YOUR LOCAL VISIBILITY TO{" "}
          <strong>REACH TARGETED</strong> CUSTOMERS FASTER AND{" "}
          <strong>INCREASE YOUR SALES.</strong>
        </>
      ),
      slideImg: DirectorySlide,
      comingSoon: false,
    },
    {
      slideTitle: "STRENGTHEN BUSINESS CONNECTIONS",
      slideDesc: (
        <>
          RAISE YOUR PROFILE, <strong>BUILD CONNECTIONS&nbsp;</strong>AND
          RECEIVE REFERRALS. INCREASE<strong> BRAND AWARENESS</strong> AND GET
          YOUR NAME OUT TO YOUR COMMUNITY. ESTABLISH YOUR EXPERTISE AND{" "}
          <strong>SHARE IDEAS AND KNOWLEDGE</strong>
        </>
      ),
      slideImg: NetworkingSlide,
      comingSoon: false,
    },
  ];

  return (
    <div className="featured-slider">
      {slides.length > 0 && (
        <Carousel
          afterChange={onChange}
          draggable
          className="home-slider"
          arrows={true}
          prevArrow={<FontAwesomeIcon icon={faChevronLeft} size="10x" />}
          nextArrow={<FontAwesomeIcon icon={faChevronRight} />}
        >
          {slides.map((slide, index) => {
            return (
              <div key={index} className="slide-content">
                <div className="inner-slide">
                  <div className="text">
                    <h3>{slide.slideTitle}</h3>

                    <p>{slide.slideDesc}</p>

                    {slide.comingSoon ? (
                      <button className="coming-soon">Coming Soon</button>
                    ) : (
                      <button className="learn-more">Learn More</button>
                    )}
                  </div>

                  <div className="slide-img">
                    <img
                      src={slide.slideImg}
                      alt={slide.slideTitle}
                      className="carousel-img"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>
      )}
    </div>
  );
}
