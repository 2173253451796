import React from "react";
import bizzllLogo from "../../assets/images/bizzll-logo.png";

export default function BizzllWord({ useImage }) {
  return (
    <span className='bizzll-word'>
      {useImage ? (
        <>
          <img src={bizzllLogo} alt='BIZZLL' />
        </>
      ) : (
        <>
          <span className='b'>B</span>
          <span className='i'>I</span>
          <span className='z'>Z</span>
          <span className='zz'>Z</span>
          <span className='l'>L</span>
          <span className='ll'>L</span>
        </>
      )}
    </span>
  );
}
