//** Import Assets */
import noImage from "../../assets/images/Bizzll-DirectorySquare.png";

//** Import Components */
import moment from "moment";
import { Button } from "antd";

export default function ListingItem(props) {
  const { event } = props;
  const listingData = {
    coverImg: event.meta_data.event_image || noImage,
    name: event.name,
    event_mode: event.meta_data.event_mode,
    userName: event.meta_data.event_title,
    eventDate: event.meta_data.event_date,
    eventStartTime: event.meta_data.event_starttime,
    eventEndTime: event.meta_data.event_endtime,
    businessLink: event.businessMetadata
      ? event.businessMetadata.business_meta_data.business_handle
      : "",
    eventDescription: event.meta_data.event_description,
    eventVenue: event.meta_data.event_venue && event.meta_data.event_venue,
    eventVenueAddress:
      event.meta_data.event_venue_address &&
      event.meta_data.event_venue_address,
    tickets: event.tickets,
  };

  // Build URL
  let eventUrl = `/events/${event.id}`;

  return (
    <div className="listing-item">
      <div className="cover-img">
        <a href={eventUrl}>
          <div
            className="image"
            style={{
              backgroundSize: "cover",
              backgroundImage: `url(${listingData.coverImg})`,
            }}
          ></div>
        </a>
      </div>

      <div className="event-category"></div>

      <div className="event-info">
        <div className="event-name">
          <h3>{listingData.name}</h3>
        </div>

        <div className="event-description">
          {listingData.eventDescription &&
            listingData.eventDescription.substring(0, 90) + "..."}
        </div>

        <div className="event-meta">
          <div className="meta-row">
            <div className="label">Event Type</div>

            <div className="value">
              {listingData.event_mode
                ? listingData.event_mode === "online"
                  ? "Online"
                  : "On Location"
                : null}
            </div>
          </div>

          <div className="meta-row">
            <div className="label">Date</div>

            <div className="value">
              {moment(new Date(listingData.eventDate)).format("DD MMM. YYYY")}
            </div>
          </div>

          <div className="meta-row">
            <div className="label">Time</div>

            <div className="value">
              {moment(listingData.eventStartTime, "HH:mm").format("hh:mm A")} -{" "}
              {moment(listingData.eventEndTime, "HH:mm").format("hh:mm A")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
