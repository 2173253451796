import { Avatar } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

export default function ChatItem(props) {
  const { data } = props;

  const isToday = (someDate) => {
    const today = new Date();
    const someDateArray = someDate.split(" ")[0].split("-");
    return (
      someDateArray[2] == today.getDate() &&
      someDateArray[1] == today.getMonth() + 1 &&
      someDateArray[0] == today.getFullYear()
    );
  };

  return (
    <div className="chat-item">
      <div className="avatar">
        <Avatar size={40}>{`${data.userInfo.user_fname
          .substring(0, 1)
          .toUpperCase()} ${data.userInfo.user_lname
            .substring(0, 1)
            .toUpperCase()}`}</Avatar>
      </div>
      <div className="latest-message">
        <div className="sender-name-container">
          <div className="sender-name">
            {`${data.userInfo.user_fname
              .substring(0, 1)
              .toUpperCase()}${data.userInfo.user_fname.substring(
                1,
                data.userInfo.user_fname.length
              )} ${data.userInfo.user_lname
                .substring(0, 1)
                .toUpperCase()}${data.userInfo.user_lname.substring(
                  1,
                  data.userInfo.user_lname.length
                )}`}
          </div>
          <div
            className={
              data.component == "follow"
                ? "message-type business-message"
                : data.component == "like"
                  ? "message-type like-notification"
                  : "message-type private-message"
            }
          >
            {data.component == "follow"
              ? "Follow"
              : data.component == "like"
                ? "Like"
                : data.component == "admin_appointment" || data.component == "user_appointment"
                  ? "Appointment"
                  : "Comment"}
          </div>
        </div>
        {
          data.component == "admin_appointment" ?
            <div>
              {`You have an appointment booked with ${data.component_action}`}
            </div> :
            data.component == "user_appointment" ?
              <div>
                {`You have an appointment on ${data.component_action}`}
              </div>
              :
              <div className="latest-message-text">{data.businessInfo.name}</div>
        }
      </div>
      <div>
        <div className="message-time">
          {isToday(data.date_notified)
            ? moment.utc(data.date_notified).local().format("LT")
            : moment.utc(data.date_notified).local().format("L")}
        </div>
        {data.is_new ? (
          <div>
            <FontAwesomeIcon
              icon={faCircle}
              color="#1890ff"
              className="unread-circle"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
