import React, { useEffect, useState } from "react";

//* Placeholder Assets
import square from "../../assets/images/ad-space-square.jpg";
import skyscraper from "../../assets/images/ad-space-skyscraper.jpg";
import axiosGeneral from "../../utils/axiosGeneral";

export default function Advertisements(props) {
  const { type } = props;

  const [ad, setAd] = useState({});

  useEffect(() => {
    const APIURL = `/api/advertisement/random/${type}`;

    axiosGeneral()
      .get(APIURL)
      .then((res) => {
        setAd(res.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  return (
    <div className="advertisement">
      {type === "square" &&
        (ad.id ? (
          <a href={`/business/${ad.businessHandle}`}>
            <img src={ad.meta_data.ad_image} alt="Square Ad" />
          </a>
        ) : (
          <img src={square} alt="Square Ad Placeholder" />
        ))}

      {type === "skyscraper" &&
        (ad.id ? (
          <a href={`/business/${ad.businessHandle}`}>
            <img src={ad.meta_data.ad_image} alt="Skyscraper Ad" />
          </a>
        ) : (
          <img src={skyscraper} alt="Skyscraper Ad Placeholder" />
        ))}
    </div>
  );
}
