import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageTitleNoAdd from "../../components/header/PageTitleNoAdd";

export default function FreeTrialCPC() {
  // Text state
  const [text, setText] = useState("");

  // Get the route code
  const params = useParams();

  // Check for the specific trial code
  useEffect(() => {
    if (params.code === "jJnGdVrb") {
      setText("Redirecting...");
      window.location.replace(
        "/checkout/?type=membership&membershipID=2&cpc=jJnGdVrb"
      );
    } else {
      setText("Invalid Code");
    }
  }, [params.code]);

  return (
    <div>
      <PageTitleNoAdd pageTitle="" />

      <h2 style={{ textAlign: "center" }}>{text}</h2>
    </div>
  );
}
