import React from "react";
import { Modal, Button } from "antd";

const DeleteModal = (props) => {
  const { showModal, setShowModal, deleteComment } = props;
  return (
    <div>
      <Modal
        title="Delete Comment"
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={[
          <Button
            key="back"
            onClick={() => setShowModal(false)}
            className="cancel-ad-btn"
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={async () => {
              setShowModal(false);
              await deleteComment();
            }}
            className="delete-ad-btn"
          >
            Delete
          </Button>,
        ]}
      >
        Are you sure you want to delete this Comment?
      </Modal>
    </div>
  );
};

export default DeleteModal;
