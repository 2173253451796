import React from "react";
import loadingImg from "../assets/images/loading.png";

function LoadingContent() {
  return (
    <div style={{ margin: 60, textAlign: "center" }}>
      <img src={loadingImg} alt='Loading Content' />
    </div>
  );
}

export default LoadingContent;
